
export const geoPoly = [
    [
        [30.42799547747532,80.53170106151902],
        [30.446453772602922,80.57752280929571],
        [30.446453772602922,80.60605609599963],
        [30.433128601319837,80.62032273935216],
        [30.410574163644696,80.62745606102783],
        [30.391091136322046,80.63934493048725],
        [30.380835350345137,80.65598934773169],
        [30.34698362130426,80.66431155635394],
        [30.336723208682614,80.67857819970652],
        [30.336723208682614,80.69641150389629],
        [30.31927803931893,80.7332669992224],
        [30.307988332952092,80.73921143395279],
        [30.307988332952092,80.75466696425065],
        [30.28027172245443,80.7653669467648],
        [30.245358185137306,80.79152245957681],
        [30.222760459712788,80.82124463322725],
        [30.164187655083325,80.8699889980141],
        [30.128204078215703,80.87355560975135],
        [30.115864023931906,80.84145566220894],
        [30.10455095305859,80.84740009693928],
        [30.095293840735224,80.83788900137046],
        [30.098379641174844,80.81767792328884],
        [30.05516967174694,80.782011314908],
        [30.054140632964888,80.76298912377166],
        [30.026352544316055,80.75585580209605],
        [30.000615885419435,80.73445583706774],
        [30.000615885419435,80.72018919371521],
        [29.95633321655167,80.67025594198304],
        [29.964573347333143,80.63340044665688],
        [29.95324299140161,80.59060051660049],
        [29.89760252019866,80.57157832546409],
        [29.861522646603063,80.55493390821965],
        [29.82749252810862,80.50737843037939],
        [29.79448255462603,80.4931117870268],
        [29.80170441737475,80.45387851780879],
        [29.80686257159489,80.43366743972712],
        [29.79551428121907,80.41702302248262],
        [29.758365423759756,80.38967862272409],
        [29.748043851392737,80.36471199685735],
        [29.70777940190095,80.3730340889316],
        [29.671631035742053,80.38730073228294],
        [29.655101738576207,80.41107847120429],
        [29.628235839596826,80.41583401898805],
        [29.59309269752761,80.40632292341928],
        [29.561039747769,80.36708965420127],
        [29.553800576684438,80.34450080222643],
        [29.54242370996502,80.34212292060113],
        [29.532079973235,80.35638956395371],
        [29.511389326001606,80.33736737281731],
        [29.50207715454431,80.31834518168102],
        [29.485520068500293,80.31596740778912],
        [29.49069444859944,80.29932299054468],
        [29.475170515603594,80.28386746024677],
        [29.45239777983339,80.30288965138311],
        [29.450327277594454,80.2707897038407],
        [29.445150837167873,80.24463419102875],
        [29.413051014759688,80.24225641713565],
        [29.400622553855726,80.26008972132666],
        [29.383012966898093,80.27911191246295],
        [29.36850866538869,80.27554525162452],
        [29.346748338540223,80.28030079940834],
        [29.32454180079995,80.2994492221431],
        [29.314175849501808,80.31609363938753],
        [29.291367049895513,80.30777143076534],
        [29.233285303473536,80.29588256130586],
        [29.195929625324325,80.2911270135221],
        [29.208383031282395,80.27448259627761],
        [29.206307568688814,80.26021595292502],
        [29.222910092472432,80.25308263124936],
        [29.201118728332176,80.24238264873526],
        [29.187626514271358,80.25664929208659],
        [29.16582764990119,80.26021595292502],
        [29.13987058467616,80.27210482238576],
        [29.137793736119534,80.26021595292502],
        [29.121920250588218,80.23617382561144],
        [29.135695242427985,80.18571179709141],
        [29.116409737161177,80.1636346596137],
        [29.054396128335227,80.13682670696335],
        [28.988207155191333,80.10528793913875],
        [28.91225057610626,80.05999530657783],
        [28.828012594203102,80.06314918336017],
        [28.82939409509264,80.12307284222703],
        [28.758914185548235,80.21138139213627],
        [28.757531750339737,80.25395872869973],
        [28.727113545485764,80.257112605482],
        [28.707751895821218,80.28392055813407],
        [28.662099544289873,80.32334401791354],
        [28.631653590812533,80.37380604643363],
        [28.634421769935344,80.44949908921353],
        [28.581813885267394,80.466845411517],
        [28.554114947481963,80.52046131681936],
        [28.573504969533303,80.52676907038392],
        [28.667634222516426,80.50784580968855],
        [28.692536656805913,80.54884620786163],
        [28.65656457380082,80.59930823638172],
        [28.637189876037738,80.66711658720351],
        [28.584583377982852,80.6876167862909],
        [28.576274681037674,80.74754044515782],
        [28.525470387466527,80.79315065276529],
        [28.493598938324837,80.86411288037112],
        [28.51022872194281,80.871997572326],
        [28.499142490734954,80.90669021693458],
        [28.464490512249427,80.91142103210717],
        [28.45617234585127,80.95557530706259],
        [28.438147406706037,80.97449856775637],
        [28.447853524909576,81.01865284271179],
        [28.40763666709367,81.02653753466836],
        [28.3882161647591,81.07857650157865],
        [28.35907874030019,81.2157701416159],
        [28.282728536076576,81.23627034070171],
        [28.241059874115066,81.28200155404915],
        [28.179916386738682,81.32300195222064],
        [28.14376970034104,81.31827113704634],
        [28.14237919941108,81.37504091913087],
        [28.177699443694394,81.37811938477495],
        [28.16298580654984,81.44488211710592],
        [28.137232073779884,81.45531379403195],
        [28.115152517100483,81.48034981865555],
        [28.08202465719397,81.48034981865555],
        [27.997318145021453,81.62847963101416],
        [27.98994921051907,81.69315602796007],
        [27.85810622628493,81.88446714933798],
        [27.865484154538933,81.92202118627387],
        [27.930019592586362,81.96792056475186],
        [27.92448949186098,82.06597832786207],
        [27.871017271126362,82.1139640417241],
        [27.86848219210586,82.1541262332712],
        [27.74668557841356,82.35858710103366],
        [27.678345986816282,82.46499020568478],
        [27.68388862951977,82.53801194417144],
        [27.718985499468488,82.70491877499802],
        [27.591474555511297,82.75499082424523],
        [27.500833122345668,82.7362138057772],
        [27.5026836948674,82.93024299661369],
        [27.46936862981829,82.95736535662252],
        [27.44530148390872,83.04290510742038],
        [27.460112702522736,83.18058234247502],
        [27.428636582761172,83.20144569632822],
        [27.389741924440955,83.27238109942971],
        [27.332300992552817,83.29741712405331],
        [27.345274130034255,83.35792085022871],
        [27.38418442723578,83.38712954562277],
        [27.417525220430562,83.40799289947591],
        [27.447153034919495,83.38504321023754],
        [27.482325731555463,83.39338855177834],
        [27.473070846942605,83.61454010262361],
        [27.347127311409622,83.86281401347765],
        [27.431270850664646,83.8722166173763],
        [27.446083907380952,84.0537277958993],
        [27.494212602958598,84.09545450360565],
        [27.520119344826867,84.11005885130322],
        [27.518269065570948,84.14761288823917],
        [27.494212602958598,84.15178555900962],
        [27.47385302777292,84.19977127287268],
        [27.446083907380952,84.22480729749628],
        [27.442380829621953,84.25818866366183],
        [27.38496723808747,84.28322468828543],
        [27.336790909759145,84.62538369147933],
        [27.219969126007584,84.68797375303888],
        [27.149445870578717,84.68797375303888],
        [27.052867518137433,84.63790155516023],
        [27.00640631374405,84.73595931827163],
        [26.99711176696114,84.79229037367554],
        [27.013841397936545,84.81941273368432],
        [26.984098110907368,84.85488043523503],
        [26.94876774418958,84.97380155219855],
        [26.91528665026742,84.97797422296895],
        [26.883656494256783,85.05934130299755],
        [26.857601481324068,85.03013260760247],
        [26.868768650537362,85.09898167531873],
        [26.872490795265136,85.19286676765859],
        [26.805473455265897,85.18452142611665],
        [26.758910315674783,85.20955745074025],
        [26.751458441320764,85.32430589693331],
        [26.781263006348738,85.40984564773231],
        [26.784988027031275,85.44739968466826],
        [26.803611296471217,85.49538539853029],
        [26.848294664933633,85.57883881394406],
        [26.853878846615814,85.64560154627407],
        [26.796418996628617,85.74323494095518],
        [26.67716975203581,85.72654425787249],
        [26.632419080650948,85.77035730096514],
        [26.604441005525004,85.8204293502123],
        [26.610037168261684,85.84546537483703],
        [26.574590181464984,85.8579833871483],
        [26.61749829263536,85.94560947333252],
        [26.654796609426725,85.9643864918005],
        [26.6603903063816,86.06661692568116],
        [26.608171811104825,86.14172499955305],
        [26.611902494995533,86.1855380426457],
        [26.59324785876281,86.21474673803976],
        [26.61563305718336,86.25855978113134],
        [26.589516566541278,86.40043058733426],
        [26.53726570960498,86.54438772892127],
        [26.49806194175102,86.57151008893123],
        [26.47191866362121,86.63201381510555],
        [26.425219462990682,86.73841691975667],
        [26.456976979653547,86.76345294438136],
        [26.440165267122424,86.84482002440882],
        [26.462580338448447,86.89280573827199],
        [26.486858407493557,86.90115107981285],
        [26.486858407493557,86.9366187813635],
        [26.51673307763801,86.94496544675678],
        [26.52793369893425,87.01172817908781],
        [26.58765187993643,87.03885053909659],
        [26.591383232942093,87.07223190526213],
        [26.451374354453023,87.09309525911425],
        [26.40653548972105,87.17863500991331],
        [26.40653548972105,87.26834743148271],
        [26.369156453100757,87.26834743148271],
        [26.352331940668634,87.3455418407409],
        [26.408404123904035,87.38309587767685],
        [26.43269361379207,87.43734059769446],
        [26.430825373146988,87.51870767772306],
        [26.38037143436408,87.5959020869791],
        [26.399060650387213,87.65223314238301],
        [26.438298154025603,87.6772691670077],
        [26.412141301480048,87.73777289318201],
        [26.43269361379207,87.7732405947317],
        [26.47005206535725,87.79201761319968],
        [26.44016627353119,87.84000332706273],
        [26.484992051652668,87.88798904092585],
        [26.45697798591644,87.90467972400751],
        [26.42335210776936,87.92345674247548],
        [26.389716420477455,87.9881331394202],
        [26.369156453100757,87.99647848096322],
        [26.371260997595925,88.02810650575236],
        [26.4366850987102,88.09573982758195],
        [26.51659746894684,88.09844516045507],
        [26.56500216997084,88.12820382206161],
        [26.751169225694,88.19313181101819],
        [26.864654142836415,88.18231047952588],
        [26.903261370250405,88.13902515355397],
        [26.980001384609494,88.13295672128652],
        [27.034165357599377,88.08342129199059],
        [27.0301541065988,88.04739552522926],
        [27.108347639216447,87.9911052646649],
        [27.340600202646883,88.06540840860998],
        [27.372596981411533,88.04514391480683],
        [27.422573430078117,88.07891807114572],
        [27.486510291617265,88.04739552522926],
        [27.590328542003405,88.09693095452519],
        [27.743878953661635,88.16447926720298],
        [27.799661919297122,88.19600181311824],
        [27.825551427263463,88.17798892973758],
        [27.84944395567389,88.20500825480917],
        [27.88129247302271,88.13520833170895],
        [27.863378833684337,88.11044061706099],
        [27.90119304201646,88.04514391480683],
        [27.889253139621133,87.9820988229751],
        [27.913131627074918,87.94156983536897],
        [27.913131627074918,87.86276347057901],
        [27.944961410756235,87.85826024973414],
        [27.950928451436624,87.83124092466375],
        [27.807628579002582,87.72766684522583],
        [27.809620152656223,87.66687336381653],
        [27.837498349139793,87.6578669221256],
        [27.815594654529107,87.61058310325211],
        [27.863378833684337,87.56555089480099],
        [27.843471316847427,87.49124775085596],
        [27.835507286826996,87.40568655479876],
        [27.857406962432023,87.41919621733456],
        [27.835507286826996,87.3538995150792],
        [27.846564899721542,87.28219398884391],
        [27.81868903211239,87.2123940657437],
        [27.856518831075164,87.11557481757313],
        [27.946063064252215,87.0457748944741],
        [27.950041087428303,86.98272980264244],
        [27.981859996232274,86.93319437334645],
        [28.03155824905332,86.86564606066867],
        [28.01366951942991,86.83862673559827],
        [28.047456846121307,86.75081392911864],
        [28.077260380883928,86.7620719812308],
        [28.103083423666405,86.74405909785008],
        [28.099674591012956,86.66982409304762],
        [28.07385072818012,86.63830154713116],
        [28.095702093349743,86.60903061163827],
        [28.115563110967784,86.57075323445451],
        [28.038084360229306,86.53247585727075],
        [27.96054977200589,86.51896619473501],
        [27.910818791196846,86.41989533614316],
        [27.966515951180725,86.33658575050833],
        [27.952594353429973,86.3140696462828],
        [27.976458850131635,86.22850845022435],
        [28.099674591012956,86.22400522937949],
        [28.175124090387044,86.18347624177329],
        [28.141376935349484,86.17446980008356],
        [28.101660784699305,86.13394081247736],
        [28.097688360562543,86.09116021444873],
        [28.0221843781527,86.07990216233537],
        [27.93071393010537,86.1294375916325],
        [27.900869849097262,86.05963766853228],
        [27.944638348657605,85.95381197867198],
        [27.994353758652665,85.97407647247502],
        [28.048020655974383,85.90652815979848],
        [28.10761914515693,85.90427654937605],
        [28.115563110967784,85.8772572243044],
        [28.143362356596754,85.87050239303716],
        [28.18324272974081,85.84915382450583],
        [28.1826431046277,85.83010610122506],
        [28.203028471864556,85.8158203087653],
        [28.19643363144712,85.80221479213577],
        [28.214418601092206,85.77500375887831],
        [28.24019177612358,85.74711244978897],
        [28.25037945400875,85.74915327728286],
        [28.256970962270913,85.74303079479989],
        [28.29231845886801,85.73350693316092],
        [28.32406129019121,85.73622803648664],
        [28.330648237793696,85.72738445067796],
        [28.336636017721332,85.73282665732921],
        [28.352801337904822,85.72194224402534],
        [28.37075991859429,85.72330279568882],
        [28.386315815252374,85.71174733628328],
        [28.379732320724557,85.68521657885753],
        [28.345012592280284,85.68725740635136],
        [28.348006119635386,85.66548857974658],
        [28.327648469929017,85.66276747641945],
        [28.31806704990497,85.65528444227442],
        [28.303693300929922,85.66276747641945],
        [28.293510720296283,85.6491619597914],
        [28.270746135338143,85.63283533983628],
        [28.256365995856868,85.6069848582423],
        [28.305490125776913,85.60018209992757],
        [28.308484766414935,85.56208665336618],
        [28.33423519521891,85.50562375935732],
        [28.33004368999603,85.49133796689756],
        [28.336031503988764,85.45528334782995],
        [28.327648469929017,85.41310624628085],
        [28.312677121676487,85.41650762543821],
        [28.279133653859077,85.3790924547086],
        [28.2893176097986,85.34779976646337],
        [28.303693295458615,85.34099688400755],
        [28.2881195424183,85.27364957669477],
        [28.29770366014226,85.26888764587392],
        [28.293510714823768,85.25596240507758],
        [28.31567155450442,85.24507799177377],
        [28.34142024261068,85.20154033856113],
        [28.323456699457097,85.18317289111224],
        [28.331840064151578,85.16752654699036],
        [28.336031498519077,85.12330861794595],
        [28.34740741544529,85.10834254965437],
        [28.392300450101075,85.12875082459715],
        [28.404867094956984,85.11922696295682],
        [28.42580819080318,85.11446503213739],
        [28.443923834285172,85.11497736527826],
        [28.446108002533165,85.10473063021732],
        [28.451022216170756,85.10162555898665],
        [28.459212064726174,85.10069403761776],
        [28.469584962041395,85.11125127980125],
        [28.472587452815247,85.11311432254041],
        [28.47995684111811,85.11342482966296],
        [28.481048558620472,85.11746142226258],
        [28.486234062636953,85.11808243650898],
        [28.489508986599148,85.12429257897037],
        [28.488144447293394,85.13050272143039],
        [28.488417356565606,85.13981793512238],
        [28.48787153731635,85.14820162744547],
        [28.490787422239308,85.15278648234778],
        [28.501157218955214,85.1568230749474],
        [28.51098029696594,85.15775459631635],
        [28.515618654736585,85.16303321740878],
        [28.51943832560646,85.16365423165513],
        [28.522166577228987,85.16675930288454],
        [28.528714093069965,85.16551727439304],
        [28.53417004559016,85.1676908242548],
        [28.533624463048596,85.18663175876009],
        [28.55462735147715,85.18911581574434],
        [28.561718289963537,85.18601074451499],
        [28.57017224615778,85.1900473371146],
        [28.5799888913145,85.18104263054516],
        [28.587623426384113,85.18228465903798],
        [28.591167843533512,85.17576400945399],
        [28.597983739203315,85.17390097605141],
        [28.6053443617663,85.18849481083458],
        [28.609705968861775,85.19066836069499],
        [28.626878036298137,85.19812053164912],
        [28.631783825442838,85.18570024672647],
        [28.641049690388584,85.18911582508099],
        [28.645409814277684,85.17731655440463],
        [28.643502282375863,85.15775460565169],
        [28.649497265824934,85.15464953442097],
        [28.65658180445682,85.14440279936133],
        [28.66148620463248,85.14471330648388],
        [28.666390375348243,85.13601910703824],
        [28.676198028298998,85.13291403580752],
        [28.687094343624366,85.11459411554739],
        [28.6835531654923,85.11024701582517],
        [28.678377382053455,85.09410064542669],
        [28.672111617804177,85.08882202433426],
        [28.67129431657878,85.08354340324189],
        [28.6800118676137,85.06988108982767],
        [28.680829100828788,85.06273942599734],
        [28.68300835822768,85.05870283339777],
        [28.680556690465394,85.05404522655175],
        [28.670477008978068,85.05187167669123],
        [28.66938732906212,85.04224592881383],
        [28.65876163967161,85.04162491456736],
        [28.657126822617826,85.02951513676851],
        [28.649769830413334,85.02392600855359],
        [28.639687187997865,85.01802637321481],
        [28.63668945877245,85.00902166664662],
        [28.605071828824634,84.98511261817146],
        [28.599892171781946,84.99256478912554],
        [28.590350029970637,84.98138653269564],
        [28.590350029970637,84.97486588311045],
        [28.58735089221541,84.97082929051078],
        [28.586805585249934,84.96555066941971],
        [28.58042791617349,84.94926609871197],
        [28.595650731351967,84.94445042599443],
        [28.593959416290474,84.93546117025528],
        [28.595650731351967,84.92069310725526],
        [28.58860340572832,84.90624608910389],
        [28.594523191001286,84.90239355092962],
        [28.590294806964934,84.89950414729856],
        [28.5908586013297,84.89179907095121],
        [28.582683287122535,84.88890966732151],
        [28.58070984018724,84.8783151873431],
        [28.582683287122535,84.86932593160395],
        [28.571451733855454,84.85778959562151],
        [28.61085617452693,84.78005645006692],
        [28.618594596598996,84.7488029173191],
        [28.620001521167964,84.72556311091569],
        [28.633366364276384,84.71514526666647],
        [28.63547644710367,84.69270683289972],
        [28.643213054441972,84.70232330451404],
        [28.652355582063393,84.69831644134092],
        [28.670638245992222,84.70392604978224],
        [28.6769661173783,84.69190546026482],
        [28.68399663729015,84.6702683991312],
        [28.69243263818784,84.6694670264963],
        [28.70157087207555,84.65343957380406],
        [28.718439361261076,84.65103545590097],
        [28.734602443830838,84.62699427686431],
        [28.735305129858006,84.57330231034808],
        [28.750763026903854,84.55727485765584],
        [28.74092644797213,84.52602132490802],
        [28.74935785805721,84.5164048532937],
        [28.735305129858006,84.49156230162038],
        [28.747250069339003,84.48034308473791],
        [28.753573307877616,84.46030876887261],
        [28.771838290485206,84.43546621720105],
        [28.78307669096867,84.44508268881538],
        [28.7929092980942,84.44508268881538],
        [28.803883262134676,84.43060938691207],
        [28.820991371598723,84.43427038087805],
        [28.83275156671411,84.4159654110444],
        [28.829544372527195,84.39888077253238],
        [28.849854933146574,84.40864342311124],
        [28.865886785227687,84.37691480873144],
        [28.85947434104108,84.35860983889773],
        [28.87336747012165,84.34152520038572],
        [28.860543109208137,84.32810155584087],
        [28.886190248402116,84.28050863427188],
        [28.899011443601623,84.26464432708264],
        [28.889395695633112,84.25244101386068],
        [28.892601043914567,84.2243733934481],
        [28.932125534133974,84.2243733934481],
        [29.037806131701302,84.25000035121622],
        [29.054875683036045,84.2194920681593],
        [29.045274407921468,84.18898378510238],
        [29.0740755517606,84.18532279113515],
        [29.107133607510306,84.2011870983244],
        [29.125257712144844,84.20240742964722],
        [29.13247116070319,84.1743398396207],
        [29.1881543953195,84.16274623920248],
        [29.208395355254126,84.19366250698113],
        [29.238749300437718,84.2013915739252],
        [29.238749300437718,84.18206890656484],
        [29.250551182526465,84.11443957079848],
        [29.275836345504814,84.12216863774466],
        [29.297745094614328,84.08738783649403],
        [29.285948659718514,84.07192970260365],
        [29.30280029244983,84.04874250177124],
        [29.287633948118426,84.02748756767181],
        [29.299430188370124,83.99077449968615],
        [29.31459478058993,84.00236810010233],
        [29.333126221997986,83.96951956558871],
        [29.31459478058993,83.9463323647542],
        [29.328072526430446,83.90768703003152],
        [29.31796438398547,83.89609342961529],
        [29.312909937162758,83.8613126283646],
        [29.296059973049253,83.85165129468334],
        [29.30280029244983,83.81493822669569],
        [29.279207228105847,83.79754782607029],
        [29.253922898820704,83.80334462627945],
        [29.242121405634975,83.76856382502882],
        [29.240435366923975,83.71639262315273],
        [29.203335489911723,83.66035688780465],
        [29.158190489980825,83.63655055262063],
        [29.179980212071285,83.6132598754595],
        [29.174170071792332,83.59995091708277],
        [29.182885158840264,83.57666023992152],
        [29.203217482829615,83.57000576073318],
        [29.19886089564615,83.55336956276034],
        [29.2148341434183,83.52176078661455],
        [29.230804901303998,83.52675164600498],
        [29.25983626108733,83.50512458864188],
        [29.27579999743641,83.4701885729043],
        [29.303910965377128,83.43795698656669],
        [29.334568649575573,83.45260770763002],
        [29.362663431012237,83.41744597707856],
        [29.39585636434684,83.43209669814189],
        [29.429038467766105,83.394004823378],
        [29.44945283750951,83.36177323704038],
        [29.503021018384857,83.33540193912751],
        [29.503021018384857,83.27386891066465],
        [29.546364992360992,83.25628804538889],
        [29.56420711204136,83.28558948751413],
        [29.60752485268371,83.20647559377551],
        [29.602429613089853,83.15959328637604],
        [29.62790323609009,83.15959328637604],
        [29.604977265068797,83.08926982527453],
        [29.671193614785764,83.01308607574822],
        [29.668647637377745,82.94862290307304],
        [29.69410451068302,82.95448319149779],
        [29.706830529433375,82.92811189358491],
        [29.686468125608997,82.86364872090962],
        [29.69410451068302,82.82262670193336],
        [29.72464424658952,82.81383626929619],
        [29.729733299400877,82.76988410610761],
        [29.76789296598477,82.75230324083185],
        [29.76789296598477,82.68784006815662],
        [29.818749916084286,82.73765251977],
        [29.851793068246167,82.70249078921995],
        [29.839085457007442,82.62337689548139],
        [29.867040066858053,82.64388790496793],
        [29.971165502938433,82.53840271331643],
        [29.94825288963824,82.50620889130562],
        [30.02185376267431,82.39193326701542],
        [30.016779592183838,82.36263182489012],
        [30.047220719679686,82.34212081540204],
        [30.044684316192516,82.30988922906442],
        [30.070045427990493,82.25128634481393],
        [30.070045427990493,82.16924230686288],
        [30.15115730418283,82.2014738932005],
        [30.194220887981814,82.18682317213865],
        [30.194220887981814,82.15166144158707],
        [30.2347341233271,82.10770927840002],
        [30.333415189800277,82.12235999946193],
        [30.361230356901814,82.0901284131242],
        [30.325827863269367,81.99343365410988],
        [30.346059427607983,81.93190062564702],
        [30.38651001039844,81.79125370344553],
        [30.44968053059992,81.61837519490484],
        [30.419363784216387,81.61251490648016],
        [30.431996907188363,81.57149288750537],
        [30.36881493931442,81.55684216644192],
        [30.40420187755528,81.47479812849093],
        [30.42441722971192,81.41326510002807],
        [30.40420187755528,81.40447466738948],
        [30.38651001039844,81.42791582108993],
        [30.37134300272872,81.39861437896474],
        [30.31065144696305,81.42498567687761],
        [30.217011635607605,81.40447466738948],
        [30.153690976997026,81.33708135050188],
        [30.16129160490381,81.30777990837663],
        [30.15115730418283,81.2579674567632],
        [30.11567905251762,81.29605933152561],
        [30.011705162058036,81.25503731255088],
        [30.011705162056828,81.15541240932714],
        [30.052668750639995,81.09519200333187],
        [30.082449813662123,81.11669929118722],
        [30.149424422208426,81.0822876306193],
        [30.25351680207629,81.03497159733661],
        [30.268378152668433,80.94033953077223],
        [30.30180796639108,80.91883224291689],
        [30.316662004258404,80.81559726121088],
        [30.41315834158219,80.70375936436204],
        [30.47990767988621,80.60912729779773],
        [30.42799547747532,80.53170106151902],
    ],

        //india
        [
        [22.98882257813314,70.17970063499925],
        [23.21224410782439,70.31904306882679],
        [23.16457712749109,70.58587315575429],
        [23.23097195647862,70.66758647741868],
        [23.128015532382534,70.67866208183014],
        [22.822779989371114,70.44242758064576],
        [22.55770481815358,70.17903858122955],
        [22.417104573454466,69.74450089486467],
        [22.37037119391583,69.55607365837447],
        [22.253585243672205,69.18991509313037],
        [22.407658112943963,69.20011429305836],
        [22.462421284186036,69.03192460821109],
        [22.268223833362057,68.9460179459341],
        [22.038369931195888,69.14646682457982],
        [21.728361373191817,69.46145791959438],
        [21.51539643376657,69.71917790642513],
        [21.177563805304743,70.01507863204588],
        [20.838957679120085,70.46370231282424],
        [20.714015074550545,70.76914822314188],
        [20.740797187715373,71.01732302527574],
        [20.9281389836938,71.50412744484404],
        [21.159761626481753,72.0004770491106],
        [21.266542529990033,72.09592889608439],
        [21.524276229602734,72.26774222063821],
        [21.648536301530257,72.29637777473008],
        [21.810148631745122,72.25460192751888],
        [21.949700001634312,72.20913698321723],
        [21.991863042541922,72.25460192751888],
        [22.115036958396118,72.30706147863765],
        [22.22839151486575,72.29656956841285],
        [22.263998336962075,72.35252642293929],
        [22.328714802024933,72.35952102975463],
        [22.319009243640735,72.4014886706504],
        [22.289888519634317,72.46094282858454],
        [22.328714802024933,72.51689968310919],
        [22.348123892975707,72.55187271718779],
        [22.312538496391113,72.5763538410433],
        [22.287411588484957,72.67818493671126],
        [22.221512636770427,72.764119325774],
        [22.16922591971472,72.75184298447874],
        [22.212420693405434,72.63644537630927],
        [22.187414811773934,72.56769786505933],
        [21.916612894062823,72.50877142684453],
        [21.873327716974657,72.53086884117494],
        [21.893832860116007,72.56524259680054],
        [21.882441477821544,72.61925849849646],
        [21.830029408025638,72.58488474287213],
        [21.704618113555085,72.53577904738535],
        [21.658986956596095,72.55296592519815],
        [21.679522765190285,72.58733968082245],
        [21.688648852284544,72.66836353336762],
        [21.63616596294341,72.69046094769803],
        [21.58823014225615,72.62907924112372],
        [21.549413325918877,72.63398977764135],
        [21.522006965067206,72.69046094759733],
        [21.42832958413564,72.64626611893658],
        [21.36594734130712,72.6366781474828],
        [21.301910691848008,72.59739385534095],
        [21.260729527065806,72.59984912359971],
        [21.23555758745482,72.62931234270644],
        [21.091308349370152,72.62194653793009],
        [21.05235989798537,72.65386502529566],
        [21.0729809371487,72.66859663484965],
        [21.066107575020823,72.72997834132195],
        [20.95379776026904,72.74962048739349],
        [20.79091354113517,72.8380101447151],
        [20.678397771190347,72.86501809556435],
        [20.596959096122703,72.89993529809018],
        [20.468198767462653,72.85082993291184],
        [20.37385923865267,72.80909037250984],
        [20.302491017616276,72.77471661688554],
        [20.232310967269214,72.75507184903736],
        [20.08941150937035,72.72806389818814],
        [19.992534476964053,72.73297443470699],
        [19.944073583899737,72.68386906952864],
        [19.837869205302056,72.66177165519827],
        [19.78242999229886,72.68877960604621],
        [19.678429481985674,72.71333228863543],
        [19.59141458845383,72.7144966858823],
        [19.50349178968827,72.76360205106064],
        [19.454882350259112,72.74395990498903],
        [19.373834251510985,72.75869151454307],
        [19.334453469853685,72.82007322101666],
        [19.30664955069713,72.8298942940518],
        [19.29969783200532,72.78324419713223],
        [19.237119082413884,72.77833366061466],
        [19.139726958893732,72.80043107494501],
        [19.08172814679581,72.82743902579301],
        [18.998174178897813,72.81761795275659],
        [18.944770493699764,72.79061000190859],
        [18.944770493699764,72.82007322101666],
        [18.889026687929274,72.81761795275659],
        [18.984244432766673,72.87899965923017],
        [19.009781410101013,72.95511297525772],
        [19.10957011531987,72.98212092610572],
        [18.986566138056077,73.0214052182476],
        [18.972635420911686,72.96247878003408],
        [18.921546166867458,72.96002351177529],
        [18.86811797249368,72.9084628783369],
        [18.85650089241922,72.98948673088208],
        [18.793754768882764,72.96984458481046],
        [18.80250914864844,72.88660535935671],
        [18.510597180699634,72.92357761879882],
        [18.317661631151438,72.97903600796289],
        [18.218186433478607,73.07146665657072],
        [18.25330183504535,72.94822579176085],
        [17.91942065720258,73.07146665657072],
        [17.10259698725335,73.27481408350499],
        [16.68916844943854,73.32439939723787],
        [16.28146661055756,73.41683004584405],
        [15.914401035542298,73.58320521333621],
        [15.338778702396255,73.90363146183873],
        [15.094993038051157,73.93444167804071],
        [14.731770393660796,74.26102996978497],
        [14.409722541526506,74.4089190075552],
        [13.89478991224847,74.62341166777006],
        [13.571552776961852,74.68503210017411],
        [12.947788471692249,74.80211092174235],
        [12.566099731874743,74.97200236589003],
        [12.036291666961915,75.19999796578634],
        [11.710661223006056,75.51426217104873],
        [11.396728057058937,75.72993368446478],
        [10.792042674085963,75.92095702491895],
        [10.05780216314254,76.20270462916073],
        [9.474821622435755,76.30745936424876],
        [8.951724410360654,76.51080679118303],
        [8.458350137676717,76.92366368829335],
        [8.159579779944266,77.26257606651774],
        [8.098578511469327,77.52138188261608],
        [8.190076926888182,77.77402565547436],
        [8.49491851368883,78.10061394721686],
        [8.878673235849462,78.162234379621],
        [9.079528600322476,78.34093363359494],
        [9.213056676848737,78.69947524138809],
        [9.261713733070266,79.05071170609261],
        [9.2252215706541,79.36497591135503],
        [9.334686674365486,78.9952533169286],
        [9.517051952020978,78.90282266832241],
        [10.033219459522073,79.26638321950873],
        [10.093891829415199,79.21708687358478],
        [10.318279655078001,79.36497591135503],
        [10.25764992360908,79.81480506790746],
        [10.312217206373077,79.8949116300335],
        [10.749844111208617,79.83276034788037],
        [11.257937261255421,79.84508443436226],
        [11.523726403584163,79.74649174251431],
        [11.783232276502162,79.77113991547628],
        [12.277420731343597,80.04843186129648],
        [12.764673636253221,80.25177928823081],
        [13.274977386495763,80.35653447725849],
        [13.622574803575375,80.22713156921014],
        [13.784214056432816,80.23329361245021],
        [14.14301038328685,80.10389070440021],
        [14.582742546005775,80.19422106637018],
        [14.862849215194956,80.07714224480202],
        [15.243686262608477,80.07098020156195],
        [15.494870678728134,80.20590931146705],
        [15.738187881647818,80.32298813303521],
        [15.880482769682075,80.64957642477941],
        [15.844918434786578,80.79746546254967],
        [15.720393996870015,80.84676180847362],
        [15.779700887792046,81.00081288948394],
        [15.963441777385526,81.12405375429381],
        [16.24096274940881,81.23821770226232],
        [16.341511378898986,81.39843082651441],
        [16.311943607807024,81.70037094529664],
        [16.560173379946136,82.30425118285939],
        [16.695973665472522,82.35970957202346],
        [16.849370827898838,82.34122344230155],
        [16.93191788681834,82.25495483693544],
        [17.202888303592644,82.45214022062964],
        [17.587564592932765,83.19420788701757],
        [17.998274198721205,83.54544435172375],
        [18.29690566072746,84.11235232984433],
        [18.723462707605734,84.45742675130879],
        [19.09773400336637,84.7602388087841],
        [19.406058397408046,85.09298914376825],
        [19.69059420249161,85.53049421383895],
        [19.943221122368996,86.34496090818772],
        [20.26148648485737,86.67154919993186],
        [20.365505018813252,86.79479006474014],
        [20.54448366909307,86.79479006474014],
        [20.682904129786564,87.04743383759842],
        [20.80391858761807,86.97965136195415],
        [21.099270614662927,86.83749561610011],
        [21.265895742215648,86.85598174582202],
        [21.48394678197012,87.10246347543858],
        [21.575660333282073,87.36743133477859],
        [21.707396871755947,87.76796414540706],
        [21.913351119178444,88.02060791826534],
        [22.101881329949308,88.1684969560356],
        [22.050488878879335,88.23011738843974],
        [21.856171340282998,88.11920061011165],
        [21.632951854257996,88.11920061011165],
        [21.621495365378337,88.3040619073256],
        [21.71312181877532,88.4334648153756],
        [22.004791018279334,88.69227063147395],
        [22.096171981083074,88.90178010164834],
        [22.2103150094714,89.01269687997643],
        [21.96583956307292,89.08326477033654],
        [22.091316556038137,89.04795382426232],
        [22.151287930959413,89.07443703381836],
        [22.27932294972409,88.99498740515025],
        [22.2956594271279,89.02735577238508],
        [22.32832665165094,88.98910224747141],
        [22.399079390373714,88.98615966863207],
        [22.46979613761806,88.98615966863207],
        [22.6212424640579,88.93976300121852],
        [22.689130668667843,88.96036105309588],
        [22.746130787088674,88.91327979166249],
        [22.79225633660134,88.96036105309588],
        [22.876327459715768,88.94859073773677],
        [22.876327459715768,88.91622237050188],
        [22.949508319254022,88.8926817397853],
        [22.96305599222596,88.86619853022921],
        [23.11062544925126,88.88085986994582],
        [23.21613480190014,88.98973528700947],
        [23.251286095296678,88.81906571431563],
        [23.243175080162302,88.76021413752466],
        [23.289130974357178,88.69841998189446],
        [23.46199894749131,88.78081218940196],
        [23.505180666409018,88.78669734708075],
        [23.491687898289285,88.7455012433262],
        [23.613073015776592,88.6454535627824],
        [23.602287769252158,88.58660198598989],
        [23.631945061585455,88.56600393411424],
        [23.74781361903986,88.57483167063236],
        [23.817823732969956,88.59542972250966],
        [23.87165198897597,88.58660198598989],
        [23.86627016942124,88.66899419349744],
        [23.925457874413496,88.73961608564741],
        [24.008812697854736,88.73373092796868],
        [24.06524820890823,88.71607545493077],
        [24.159252229242355,88.69841998189446],
        [24.186749388899386,88.75077577070351],
        [24.323573306701633,88.68603903623227],
        [24.29675669582187,88.6330726171202],
        [24.307484020526147,88.54479525193392],
        [24.385229978425016,88.41532178299303],
        [24.46828487333383,88.23288189494014],
        [24.51113050295659,88.11517874135836],
        [24.61283030867058,88.09163811064167],
        [24.668997399947955,88.00630332429478],
        [24.765225009493733,88.06515490108569],
        [24.797284334535945,88.10929358367957],
        [24.889010521886362,88.15308661565791],
        [24.94933827938776,88.14099195595492],
        [24.96578618008637,88.21355991416829],
        [24.91095130246238,88.26193855297726],
        [24.883524717455316,88.34660117089209],
        [25.075382628130285,88.44940577835905],
        [25.212240333291476,88.45545310821132],
        [25.195825514189508,88.72153562165732],
        [25.173935646745818,88.9271448365945],
        [25.272409054538613,89.02994944406146],
        [25.414507415329282,88.85457687838107],
        [25.540070415494824,88.81829289927515],
        [25.485493918567514,88.70339363210513],
        [25.51278526833339,88.5522103858276],
        [25.654600193443883,88.46754776791278],
        [25.80713562461169,88.26798588282799],
        [25.785356836778575,88.15308661565791],
        [25.926847349267334,88.104707976849],
        [26.068168200073416,88.1833232649131],
        [26.149622027926426,88.17727593506243],
        [26.24729161654642,88.34660117089209],
        [26.377390100866435,88.44335844850832],
        [26.36655414259333,88.52197373657248],
        [26.46945468791806,88.49173708731729],
        [26.485693744813105,88.3284591813383],
        [26.642552932681255,88.41916912910386],
        [26.556036481998945,88.45545310821132],
        [26.48028098057617,88.55825771567834],
        [26.4153079503819,88.66710965299762],
        [26.31777976281259,88.72153562165732],
        [26.31235911856389,88.78805625001996],
        [26.241867678240126,88.8424822186796],
        [26.285252093913527,88.90295551719004],
        [26.252715301684816,89.07832808287037],
        [26.344879178636262,88.9513341559989],
        [26.457133353142012,88.9504859843646],
        [26.299186588952935,89.12678931798064],
        [26.159642822880187,89.1579016709727],
        [26.02925117010777,89.34457578891994],
        [25.97332459126649,89.57273304418766],
        [26.085151101775736,89.62458696583934],
        [26.206176013040263,89.66607010316125],
        [26.066520754518876,89.81126108378652],
        [25.889384848418047,89.8320026524475],
        [25.590449667874196,89.8734857897694],
        [25.29076567116836,89.88385657409918],
        [25.196961856503762,90.29868794731544],
        [25.17819240982314,90.6305530458879],
        [25.20634549525687,90.9935304974511],
        [25.12186673050664,91.56392363562242],
        [25.178322398768984,92.05212238609874],
        [25.09702446729905,92.26412580842145],
        [25.025757756443795,92.42147276003641],
        [24.941861559903828,92.50014623584451],
        [24.8579081919777,92.43072846307211],
        [24.916681546098047,92.236358699312],
        [24.752886300296936,92.29189291752971],
        [24.517315328518393,92.23173084779421],
        [24.52994644978307,92.17156877805866],
        [24.412006636017537,92.1391738174326],
        [24.395149078616328,91.9910825688533],
        [24.340346486276502,91.9355483506356],
        [24.146239412563787,91.8985255384905],
        [24.247549616774307,91.75043428991114],
        [24.150462277415542,91.7596899929481],
        [24.222229614142236,91.66250511106745],
        [24.07865461143136,91.5977151898141],
        [24.104003092966153,91.38483401998144],
        [23.97721060024014,91.38020616846359],
        [23.91376748004626,91.25525417747468],
        [23.7317246892719,91.17195285014873],
        [23.536699374370954,91.21823136532959],
        [23.358379834255643,91.30153269265554],
        [23.133015210514202,91.33392765328279],
        [23.069163773223835,91.40797327757184],
        [23.27763254149805,91.40334542605393],
        [23.239366718238884,91.46350749578949],
        [22.987305880299672,91.56352609464176],
        [22.987305880299672,91.67039576307536],
        [23.091436211827727,91.82127058909799],
        [23.28790663364873,91.77097898042484],
        [23.44372108878865,91.95328606186888],
        [23.691490756603557,91.92814025753142],
        [23.74329026218213,91.96585896403673],
        [23.656946330216073,92.06644218138626],
        [23.74329026218213,92.15445249656631],
        [23.656946330216073,92.21731700740895],
        [23.726026046544874,92.28018151825154],
        [23.60511258198902,92.31161377367295],
        [23.24748044900592,92.36190538234763],
        [22.952577901716467,92.36819183343158],
        [22.74402314891188,92.49392085511852],
        [22.587397796935804,92.53163956162382],
        [22.145572220518034,92.60079052355036],
        [22.005758324892085,92.61964987680386],
        [22.05820474740105,92.67622793656255],
        [22.16303921826278,92.7076601919839],
        [21.953292493301973,92.91511307776528],
        [22.03489762482286,92.93397243101873],
        [22.01158666431725,93.00312339294533],
        [22.09898296720155,93.04084209945222],
        [22.22124687300858,93.05970145270402],
        [22.203787109818975,93.15399821896966],
        [22.250341648113846,93.20428982764275],
        [22.48869029132102,93.13513886571621],
        [22.599005801855128,93.13513886571621],
        [22.726629167859443,93.10370661029486],
        [22.912050657839345,93.15399821896966],
        [23.050950647904187,93.14142531680011],
        [22.99887988988324,93.26715433848705],
        [23.045166001504015,93.3425917514976],
        [23.362951259477327,93.39288336017239],
        [23.67421968483211,93.44317496884713],
        [23.92155295758303,93.38659690908844],
        [24.099569967288048,93.3425917514976],
        [23.99049198841341,93.47460722426848],
        [23.97326068646224,93.59404979486982],
        [24.0192057001114,93.63176850137512],
        [24.013463470476736,93.73863816980861],
        [23.933045354792597,93.81407558282069],
        [23.944536729001797,93.90208589800073],
        [23.829576996492932,94.13468458811951],
        [24.099569967288048,94.24784070763701],
        [24.466305869335528,94.39871553365964],
        [24.714190609705,94.61873840561458],
        [25.136057817042,94.75075387838547],
        [25.210020138228884,94.57473324802538],
        [25.43731434232832,94.65645711211982],
        [25.488396644100604,94.78218613380676],
        [25.590496109780744,94.8827693511547],
        [25.754806467557444,95.039930628263],
        [25.935850950591586,95.02735772609338],
        [26.077098731434276,95.20337835645347],
        [26.09968259798471,95.12165449235903],
        [26.375980313266027,95.14051384561083],
        [26.44557950964621,95.06687145389031],
        [26.55092538875617,95.1289971053638],
        [26.64740750493344,95.20747161248914],
        [26.67955011866843,95.24670886605094],
        [26.662018906369298,95.31210428865444],
        [26.699999793322334,95.43308582047155],
        [26.802193074071923,95.50175101420683],
        [26.828456485978663,95.54752781002861],
        [26.81630795584094,95.61313418315956],
        [26.903818070997787,95.66872029237305],
        [26.88923775376206,95.7243064015849],
        [26.956291621579794,95.75373434175668],
        [27.01747988632981,95.80278090871013],
        [27.02621834967924,95.88125541583543],
        [27.055341650452533,95.949920609569],
        [27.139756458273595,95.99569740539249],
        [27.23282989704586,96.12321847946964],
        [27.272510735714334,96.25526234201351],
        [27.28994713243624,96.37297410270065],
        [27.295758656474632,96.51357426129942],
        [27.31609659495261,96.58877899729293],
        [27.371280773196375,96.61166739520479],
        [27.33643080541907,96.67379304667827],
        [27.377088034813426,96.70322098685011],
        [27.34514432535491,96.79477457849543],
        [27.249257950980535,96.88632817014064],
        [27.214369660081204,96.85036068770893],
        [27.164925876785873,96.92556542370244],
        [27.10381802254392,97.08251443795302],
        [27.10090729184118,97.13810054716657],
        [27.132921166280354,97.1675284873366],
        [27.458357675019315,96.91248633918246],
        [27.51057084271278,96.93210496596419],
        [27.6178200903055,96.89286771240069],
        [27.661269638326218,96.93864450822417],
        [27.661269638326218,96.99423061743772],
        [27.73943530840417,97.02365855760951],
        [27.73943530840417,97.09886329360307],
        [27.78283647287229,97.11521214925483],
        [27.921603759998263,97.29177979028543],
        [27.88403849299179,97.33101704384723],
        [27.892708558247463,97.37352406853893],
        [27.964932084810428,97.36044498401901],
        [28.011129797408643,97.39641246645078],
        [28.063078547935774,97.35390544175897],
        [28.08038922221499,97.30485887480546],
        [28.13518794690141,97.32774727271715],
        [28.214795459213292,97.36018905218555],
        [28.38002754912175,97.09929938506605],
        [28.32766466855722,97.00775915098808],
        [28.38002754912175,96.88417983498374],
        [28.48869862105788,96.86129477646455],
        [28.624113091135257,96.61992511658985],
        [28.439140315615475,96.5009228122891],
        [28.491448131452643,96.40480556650755],
        [28.59598601358769,96.4643067186579],
        [28.668297561214914,96.65654121021981],
        [29.11311385087575,96.35445843776552],
        [29.037109447412945,96.20799406324204],
        [29.097117583248334,96.18053199301926],
        [29.177074046064632,96.29953429731876],
        [29.38466924099326,96.03864463019926],
        [29.316847996444835,95.86929519715653],
        [29.352758984854248,95.80979404500613],
        [29.225018049353366,95.74571588115214],
        [29.197053438980504,95.59009748322029],
        [29.149096359829585,95.43447908528947],
        [29.077118753141278,95.26055264054315],
        [29.13310568842506,95.01339400853448],
        [29.2210235722835,94.79369744674852],
        [29.316847996444835,94.7021572126717],
        [29.153093638869322,94.32226524125144],
        [29.097117583248334,94.26276408910115],
        [29.033107663231448,94.38634340510549],
        [28.941023833689442,94.25818707739751],
        [28.876917198798594,94.07968362094635],
        [28.74457231830857,93.91033418790363],
        [28.736546015689214,93.81421694212327],
        [28.66026540579344,93.63571348567211],
        [28.656249097262247,93.50755715796419],
        [28.53568835774179,93.38855485366344],
        [28.555791414812802,93.27870657277106],
        [28.457820441431394,93.16253548503693],
        [28.363841670774008,93.1326181389108],
        [28.213302458122243,92.9274706226123],
        [28.183169092131962,92.81634905128504],
        [28.160563493134745,92.66676232065083],
        [28.077635460083187,92.66676232065083],
        [28.066322124451304,92.73514482608402],
        [27.981307451493066,92.74305628174551],
        [27.796208897683798,92.46097844683646],
        [27.834009803421168,92.42251328753053],
        [27.80377013132326,92.32848734256044],
        [27.883131300025212,92.27292655689627],
        [27.777303512300847,91.98657481539789],
        [27.720567643939518,91.93101402973377],
        [27.79242808358771,91.83271417817434],
        [27.788647137989557,91.74296213979363],
        [27.83023030499794,91.66603182118183],
        [27.834009803421168,91.58055368939148],
        [27.79998958026694,91.6318405684653],
        [27.735700098562532,91.64038838164498],
        [27.62594224547317,91.5762797828022],
        [27.50849308498985,91.64893619482353],
        [27.478163235766772,91.7899751122792],
        [27.49332920481254,92.01649216152413],
        [27.364351963582635,92.05068341424072],
        [27.27701731660875,92.12333982626319],
        [27.25422306137233,92.06777904059896],
        [27.143985008977637,92.03358778788242],
        [27.045058342381623,92.08487466695726],
        [26.95366395015421,92.13188763944174],
        [26.845161505992337,92.07371456141652],
        [26.916731780121168,91.89412884877765],
        [26.845161505992337,91.81770939659117],
        [26.80083340315373,91.6151978482954],
        [26.834933483109793,91.40504435478158],
        [26.7871904992181,91.32862490259504],
        [26.82811428796387,91.1108294638629],
        [26.783779516922593,91.03441001167437],
        [26.780685643023574,90.65207332542082],
        [26.900012226616383,90.39606816059342],
        [26.852296685142875,90.3043648179704],
        [26.86252313921024,90.23558731100212],
        [26.760217039246683,90.18973563969058],
        [26.698789107712116,89.86877394050407],
        [26.726094505023966,89.76178670744258],
        [26.739744747469203,89.69683017308347],
        [26.712442624912057,89.65862044699008],
        [26.726094505023966,89.62423169350609],
        [26.770451802256517,89.64715752916277],
        [26.801150556852363,89.46757181652384],
        [26.83525054140128,89.44464598086711],
        [26.865931751816888,89.35676361085325],
        [26.807971374471137,89.25741832300997],
        [26.801150556852363,89.13896817211912],
        [26.896604641646817,89.09693747341663],
        [26.934082420129045,89.0205180212302],
        [26.927269204359277,88.94409856904366],
        [26.96133116640374,88.9593824594802],
        [26.98857332236075,88.92881467860514],
        [26.94089522428966,88.87532106207539],
        [27.0974759518667,88.87150008946628],
        [27.131486338130628,88.802722582498],
        [27.14508759744048,88.74540799335716],
        [27.19607757321475,88.78743869205948],
        [27.253838035711368,88.80654355510711],
        [27.281500500259583,88.91439013509853],
        [27.34556891113283,88.88284297428322],
        [27.425602347578106,88.79270822909524],
        [27.56152608721635,88.77468128005745],
        [27.62143903852636,88.81524191539228],
        [27.848809039100487,88.89636318606188],
        [27.928475378127104,88.83777560168926],
        [28.0160405741687,88.82876212716974],
        [28.071726846635656,88.69806674664699],
        [28.11546010973325,88.62595895049571],
        [28.03593183802319,88.52230399352919],
        [27.964306093732873,88.30147386781846],
        [27.956344739862345,88.14373806373845],
        [27.89263278029884,88.13923132647932],
        [27.848809039100487,88.20232564811107],
        [27.733188848036278,88.17077848729463],
        [27.57351129634594,88.07613700484751],
        [27.449601079524527,88.07613700484751],
        [27.336268128702557,88.06404382290998],
        [27.135914911167433,88.00094950127823],
        [27.047645430915153,88.05503034839171],
        [26.98340579221582,88.1226314072826],
        [26.91912946025002,88.14967183083877],
        [26.75425429185715,88.19924594069306],
        [26.60122925426805,88.13615161906131],
        [26.456069103194253,88.10911119550514],
        [26.367269870700383,88.01897645031607],
        [26.45978014406353,87.87562623087967],
        [26.39502047419846,87.62653483337192],
        [26.45258642205262,87.49797153143163],
        [26.351827164209297,87.36940822949344],
        [26.416611072480023,87.12835203835641],
        [26.56697154407658,87.06278372910339],
        [26.4303413317825,86.75744588699837],
        [26.610084146910765,86.25926309198496],
        [26.58134421566504,85.86553797979536],
        [26.667542342729845,85.72893947148435],
        [26.86841796178163,85.64858740777277],
        [26.78237191649326,85.22272147009812],
        [26.861249954668608,85.20665105735657],
        [26.854081493337986,85.07005254904556],
        [27.04585037081749,84.66453764739288],
        [27.338882351887193,84.60829120279345],
        [27.367429554284442,84.31902377343204],
        [27.517181487171356,84.15028443963587],
        [27.453026977309065,83.92529866124244],
        [27.36029344353595,83.85298180390157],
        [27.481544706352494,83.40301024711476],
        [27.338882351887193,83.31462297703018],
        [27.431633839683343,83.21016529420422],
        [27.502928159471423,82.73608811830309],
        [27.74498311370273,82.68787688007654],
        [27.65249589453647,82.47896151442455],
        [27.929721987136062,82.02095475126691],
        [27.851601625390373,81.89239144932873],
        [28.043250848441573,81.56294798810939],
        [28.12831895828262,81.32992700334307],
        [28.347765652863558,81.21743411414639],
        [28.41845848543724,81.024589161238],
        [28.70075740068299,80.57461760445119],
        [28.60909332559382,80.4299838897694],
        [28.811884789568794,80.07743396243387],
        [28.93013568086124,80.06002740008728],
        [29.136023260920666,80.15980621604456],
        [29.125564317166194,80.22366465825871],
        [29.21965648264768,80.25160272672775],
        [29.296260337590255,80.30747886366356],
        [29.438868769276667,80.23962926881336],
        [29.619450178371366,80.407257679623],
        [29.754674334914895,80.34739039004899],
        [29.806635140271467,80.39528422170861],
        [29.80743360233501,80.50327464582989],
        [29.945862659659326,80.5711242406822],
        [29.97352539962577,80.63498268289646],
        [29.994267401338846,80.71081458302496],
        [30.1531453164898,80.8664695359202],
        [30.335883454034104,80.66691190400144],
        [30.43572822698583,80.60704461442742],
        [30.408195032929,80.52722156166072],
        [30.45981339691609,80.53919501957512],
        [30.51140442663616,80.38753121931796],
        [30.593893150730835,80.21591165586818],
        [30.765519200012122,80.2478408769752],
        [30.810091972243328,80.16402667157041],
        [30.782665018892473,80.12810629782524],
        [30.995018970482192,79.76092025509672],
        [30.940263039204993,79.60127414956133],
        [31.03606530261827,79.5014953336019],
        [31.022385156363086,79.4336457387517],
        [31.101019152670602,79.42167228083514],
        [31.188756418981242,79.30497497416951],
        [31.328638904884087,79.23313422667906],
        [31.44788657717794,79.08546157906011],
        [31.27748643622607,78.88590394714129],
        [31.335457135464182,78.77016052062936],
        [31.451291427689867,78.7901162838221],
        [31.502349331070377,78.73024899424598],
        [31.61457858316733,78.83401896284352],
        [31.648560776137217,78.80208974173644],
        [31.78097260282476,78.6983197731389],
        [31.98431327483918,78.76616936799121],
        [32.1399033490366,78.61450556773411],
        [32.281732431934515,78.50275329386028],
        [32.4132325966187,78.47082407275326],
        [32.54790578247237,78.4029744779009],
        [32.61516675301917,78.62647902564845],
        [32.69917196085774,78.73823129952228],
        [32.47386046652443,78.77814282590555],
        [32.335704339353384,78.98169161046252],
        [32.38290337361511,79.00164737365316],
        [32.35930693601763,79.0934438843363],
        [32.41660188134546,79.11739080016719],
        [32.514256381270215,79.22914307404096],
        [32.50752498863588,79.41672724804329],
        [32.59120846782817,79.40200266526318],
        [32.70777775430392,79.45889810344244],
        [32.92772583452424,79.30338390575332],
        [32.96751319857533,79.2407989237575],
        [32.9881955496614,79.1706278833363],
        [33.1136121584929,79.14756943389654],
        [33.17236697883622,79.1627415507453],
        [33.22632319394815,79.06981233505394],
        [33.24694472249938,79.07739839347732],
        [33.264389908890976,79.04515764517498],
        [33.31828936515505,79.02808901372134],
        [33.33255129385259,78.9730900901489],
        [33.41015860556746,78.90481556433457],
        [33.42282261050393,78.83654103852024],
        [33.48295140488199,78.8156793778549],
        [33.56832582648501,78.7360257644055],
        [33.62519526094354,78.75688742507077],
        [33.62203683178838,78.72085364755674],
        [33.64414340423019,78.69809547228664],
        [33.674136103722404,78.73981879361719],
        [33.745129724484244,78.77585257113122],
        [33.78612150109936,78.75688742507077],
        [33.888393937488786,78.75394890775038],
        [33.913579732351366,78.76532799538552],
        [33.92144876737599,78.72929421787342],
        [34.003243635060656,78.73877679090361],
        [34.03311059580578,78.65533014824052],
        [34.08495980689594,78.66481272127072],
        [34.09438352783938,78.70463952799645],
        [34.09438352783938,78.74825936393182],
        [34.13520752759656,78.79377571447611],
        [34.122648395711465,78.82791297738328],
        [34.16345875102053,78.86963629871389],
        [34.15247328692821,78.92084219307463],
        [34.22934151326659,78.95877248519344],
        [34.31240577952481,78.97773763125394],
        [34.32562100017476,79.04117592327805],
        [34.3907955601765,78.95930267951093],
        [34.36183534634205,78.90082179110607],
        [34.37148986358959,78.8745053913238],
        [34.40044673922442,78.87742943574386],
        [34.44868593619272,78.74584743683221],
        [34.48002648414975,78.76923979219441],
        [34.54026368602574,78.63765779328276],
        [34.50653621723461,78.58794903813816],
        [34.52821972927276,78.5587085939357],
        [34.56916207282258,78.56163263835583],
        [34.57879263751758,78.4914555722691],
        [34.549897596933874,78.44467086154452],
        [34.60045732862116,78.42712659502405],
        [34.62211636991833,78.27800032959033],
        [34.71109970277995,78.2750762851702],
        [34.725520424400614,78.19905113024333],
        [34.88158359423285,78.2312156188658],
        [34.9391321823491,78.17858281930268],
        [34.96309885240191,78.2019751746634],
        [35.03735098391002,78.11425384205614],
        [35.06368231385443,78.13764619741835],
        [35.1043594859466,78.11717788647621],
        [35.16866695140193,78.09670971722733],
        [35.24273215043098,78.0060643402],
        [35.35251078139912,78.02360860672206],
        [35.438320559771725,78.09963376164751],
        [35.493095648445006,78.13179825027157],
        [35.493095648445006,77.97682389599754],
        [35.46452199554112,77.91834300759274],
        [35.521659143265694,77.81600145288348],
        [35.46690352118068,77.71365989817423],
        [35.483572225980524,77.65517900976943],
        [35.46690352118068,77.64055878766743],
        [35.48714910699297,77.52003708289888],
        [35.467590060131755,77.43641477570135],
        [35.47379680006293,77.39086578933285],
        [35.508852379543114,77.34379538314451],
        [35.54309075872091,77.30437439077643],
        [35.52065210880603,77.19028361715252],
        [35.556590150430395,77.1248281066082],
        [35.565181120884944,77.09257843970133],
        [35.59961460969697,77.06219768917634],
        [35.60970009074815,76.99817644372379],
        [35.59816171104933,76.96208028122166],
        [35.619293049961954,76.90262266390825],
        [35.65125978602496,76.86260123227973],
        [35.67335641671963,76.84407233908047],
        [35.66592041908554,76.79580105437083],
        [35.659099486749895,76.77575027089466],
        [35.638611125932194,76.78186431680855],
        [35.63256540956937,76.75671408093515],
        [35.60525416800599,76.78389350384184],
        [35.58250201908001,76.79612159566955],
        [35.55379310596781,76.74971922734585],
        [35.51983962313159,76.75758877808434],
        [35.51630566431917,76.79909882375244],
        [35.4914785858485,76.79482041798687],
        [35.44686879355157,76.82418250715091],
        [35.43920816858517,76.84166692631173],
        [35.417842355791784,76.8329865160824],
        [35.38576970167058,76.86234860524638],
        [35.390258264913115,76.89825190175881],
        [35.39474682815564,76.93415519827124],
        [35.36675957297105,76.96223574817003],
        [35.30839096913375,76.9974626631764],
        [35.295872117149685,77.02182310105029],
        [35.29377825540436,77.00484239319303],
        [35.28797110668877,76.99683805471021],
        [35.27981095786276,76.99878957468829],
        [35.27799877483497,76.99345086296941],
        [35.268766280263236,76.99406533326331],
        [35.266530988091276,76.98270819769294],
        [35.25199306998424,76.97451016252522],
        [35.23765189743846,77.00098385229893],
        [35.225521441554704,77.00207031102074],
        [35.21092061549589,76.98997713877279],
        [35.181476670225365,77.01758712232419],
        [35.17606915628842,77.05394538391207],
        [35.16791136086751,77.08609157767849],
        [35.14759334685709,77.0834357862953],
        [35.136017947773,77.06911562419296],
        [35.12484633584448,77.06961893321257],
        [35.11647978192809,77.08177796616158],
        [35.10176134688466,77.07959522004542],
        [35.09022796475628,77.10304762704149],
        [35.07551328264026,77.11550285370724],
        [35.05443439663791,77.11580769420982],
        [35.043962615099915,77.08685281815082],
        [35.05457863991238,77.07490848272083],
        [35.050075296371276,77.04765466072311],
        [35.03827385558283,77.03572880378755],
        [35.037614241595506,77.02380294685199],
        [35.02296169764302,77.00371785269027],
        [35.01746311745433,76.98314674308496],
        [35.00984884733124,76.94006046208472],
        [35.004565104057406,76.93363680119559],
        [34.992911732766814,76.94082157281301],
        [34.981137788988086,76.93696783615067],
        [34.97692969059463,76.92679589868285],
        [34.96973532355863,76.92974637827368],
        [34.958956472771305,76.92394857982404],
        [34.94327121755663,76.91028741727666],
        [34.92758596234196,76.8966262547293],
        [34.9330123942141,76.88657503581797],
        [34.94082951370105,76.87603780145875],
        [34.94769834710537,76.86176754899381],
        [34.954663818227274,76.8317689977244],
        [34.953660788291046,76.79205013752504],
        [34.94419594574534,76.78069597774191],
        [34.9414555921184,76.77623393646591],
        [34.933934310983105,76.7746879878687],
        [34.93075965046013,76.76351465693661],
        [34.92160796561056,76.75768749591828],
        [34.9102640757787,76.7557484584705],
        [34.89971730151193,76.76109965272369],
        [34.89140777406381,76.74556725579147],
        [34.872399941588945,76.74803752774142],
        [34.8602066495377,76.74149641657155],
        [34.842031276772076,76.74613366067314],
        [34.81964254257285,76.7325654228856],
        [34.80556134632329,76.73690670113243],
        [34.80185546809092,76.71743322249682],
        [34.79683857225966,76.70035696032076],
        [34.78803065161394,76.70029123877593],
        [34.78422541875065,76.69807130849438],
        [34.78042018588737,76.70216957901818],
        [34.769217275308364,76.7007673149948],
        [34.76160747366379,76.68964474203948],
        [34.75640493020576,76.69951444599045],
        [34.746016770105534,76.68719574961499],
        [34.745212753329525,76.67390502234394],
        [34.73701471499366,76.65607184584023],
        [34.73511210899755,76.64229510312222],
        [34.74259525902889,76.63945370795184],
        [34.75137080016857,76.63290219769974],
        [34.76194368194096,76.63728603499716],
        [34.76393308575498,76.62736129444326],
        [34.75793693189715,76.62083866201183],
        [34.74594408349943,76.6102234743841],
        [34.74711590418573,76.59628333082978],
        [34.751894198286465,76.57947144625862],
        [34.75946774507724,76.578698071425],
        [34.75905537358172,76.56601731815016],
        [34.76503152546577,76.55819671934131],
        [34.762782749562334,76.54732697286178],
        [34.762550970100534,76.54068283657304],
        [34.76682696040535,76.52885549811006],
        [34.77150220344556,76.52626245313533],
        [34.7778581095787,76.51937530911854],
        [34.786209999346056,76.52026441225085],
        [34.79606616781154,76.48882771261964],
        [34.78834277789882,76.47887609317645],
        [34.7854091004743,76.46455033471509],
        [34.76956280753768,76.4519373275258],
        [34.763186095777,76.44216796387408],
        [34.75002166852763,76.44114687826271],
        [34.739265619602534,76.41917807372816],
        [34.74029161256908,76.40006460994388],
        [34.73341570857844,76.38783479309468],
        [34.73332976911077,76.36637068276133],
        [34.72100320162425,76.33008781697572],
        [34.719917267325854,76.26092419353111],
        [34.69813853140047,76.2694390823975],
        [34.69773807993529,76.25875636111962],
        [34.684850641506834,76.24941018232127],
        [34.67480987570124,76.25202732462105],
        [34.663569502227276,76.20750096860401],
        [34.65467664933102,76.18138725690031],
        [34.63259005901414,76.16159174600222],
        [34.64299501495718,76.11446748478511],
        [34.64878705759382,76.10156979558944],
        [34.66057633461636,76.10130850800462],
        [34.664472078696605,76.0892704857711],
        [34.677961488608005,76.07577441719805],
        [34.68875331434056,76.0585025889821],
        [34.684423087297716,76.03159723688091],
        [34.665305118279676,76.01003805469361],
        [34.63985820258497,75.99219249354068],
        [34.64083022153059,75.9762224889881],
        [34.63260519972336,75.95247623729063],
        [34.610955180421655,75.92685442899082],
        [34.58126213635491,75.89189232985474],
        [34.555211091998416,75.83528703683646],
        [34.513744891402304,75.78305588283442],
        [34.52594520741347,75.69901565220167],
        [34.53338888621018,75.67502116712643],
        [34.540832565006895,75.66949526902371],
        [34.536902619349675,75.6331706695966],
        [34.53865148187389,75.54205288376977],
        [34.55029731173474,75.52540938232607],
        [34.555538275463675,75.50876588088238],
        [34.54795063800599,75.49351841004635],
        [34.54236454287853,75.49090734082117],
        [34.5351768918538,75.48367912485391],
        [34.53519575500593,75.4608984145969],
        [34.540637273729764,75.45300319119373],
        [34.54527810331477,75.42372328813914],
        [34.55280196441922,75.42171973822373],
        [34.55792410471525,75.40513572491022],
        [34.548035069928986,75.39462690467835],
        [34.53894652931888,75.36443445885827],
        [34.56295019623339,75.34541958108571],
        [34.56865106342287,75.3285154754795],
        [34.56634759503427,75.31890160157033],
        [34.5869534032334,75.3008888923695],
        [34.595154752907874,75.28550358503117],
        [34.59565484302051,75.27355829620478],
        [34.602556083963904,75.26501511550487],
        [34.599355895066765,75.25400394718679],
        [34.60415677858735,75.24785293333461],
        [34.618559315815745,75.25669257755584],
        [34.6194602960953,75.26570670486248],
        [34.62836036370963,75.27083270859863],
        [34.63805952126165,75.26550938023306],
        [34.63856133560974,75.25726995918862],
        [34.64327648167862,75.20488101392999],
        [34.65469753794761,75.1484164944762],
        [34.65452259326153,75.08770658523463],
        [34.637950002140386,75.05871015534515],
        [34.63087791201206,75.02427962652786],
        [34.6520370759341,74.99514347060737],
        [34.64669137836419,74.98159428619596],
        [34.663696804270444,74.95921469102646],
        [34.67228344375533,74.93347484693619],
        [34.674166305761446,74.898904592088],
        [34.68135730277589,74.87329850068039],
        [34.67737639229606,74.84633388454006],
        [34.67388301783414,74.84165117379587],
        [34.68086180040808,74.73990607053004],
        [34.70579590331132,74.64971806664263],
        [34.73627147348863,74.60615240502432],
        [34.76228135117519,74.57956830255412],
        [34.76811914928281,74.51514581926337],
        [34.778570386823716,74.47885900342288],
        [34.770126304516936,74.47599899873717],
        [34.76980979994848,74.46777637273388],
        [34.781089431221204,74.45857059198842],
        [34.77953672460798,74.44189292521474],
        [34.772970815534336,74.43634273143448],
        [34.783077941636144,74.40368009044028],
        [34.78649026711672,74.37101744944607],
        [34.78228610282125,74.30863275325434],
        [34.72742330569771,74.2413099561744],
        [34.70222613705863,74.18353474364062],
        [34.67451758168217,74.14783555800162],
        [34.66617305426402,74.14381031282724],
        [34.658387207224614,74.14657769131324],
        [34.659576741979095,74.12833826547394],
        [34.66419037477841,74.11427507187409],
        [34.680119817616,74.10652655565563],
        [34.67008098093821,74.0923880479512],
        [34.672906759833616,74.05392209705187],
        [34.68271477484366,74.00934278485983],
        [34.68749559301685,73.96272568556745],
        [34.64052588546104,73.93231844745796],
        [34.603089583063266,73.92796483228105],
        [34.58239756324842,73.95751747921877],
        [34.54920743322524,73.94142940581656],
        [34.524957160283876,73.90431944990124],
        [34.45635104841404,73.88570580794092],
        [34.390894458713674,73.76516720836732],
        [34.34815795283477,73.75336445297017],
        [34.33117362130695,73.78767421404928],
        [34.325669048912914,73.85559424740356],
        [34.34536509806597,73.92012365454612],
        [34.33307349581065,73.94178507217384],
        [34.31180632548457,73.92345639556873],
        [34.30929426957559,73.94277331744401],
        [34.29669942234376,73.93903398107821],
        [34.274024151735475,73.94845055421638],
        [34.27165675844482,73.98842135338529],
        [34.2329183204898,74.00637237724325],
        [34.204271223136814,74.0066921448013],
        [34.12811868435399,73.9275211579693],
        [34.11414863569277,73.92932248081927],
        [34.1035471603056,73.92531128381634],
        [34.10448221695239,73.90508259811833],
        [34.08936750196485,73.90172530959873],
        [34.07714098551999,73.90592429688728],
        [34.0644332046074,73.89675448851486],
        [34.05220693858029,73.89688471190593],
        [34.043165513132735,73.9073523390131],
        [34.03845878221624,73.92014497405994],
        [34.02374730432825,73.92713018062909],
        [34.01457736631673,73.95329670271016],
        [34.01311603510919,73.97888197280683],
        [34.014643687176175,73.9922698915814],
        [34.02243459115733,74.0062390623402],
        [34.03925788043681,74.01730197317384],
        [34.03681383326817,74.03940867172628],
        [34.03078998347434,74.04943578808717],
        [34.03563651394323,74.06710954621593],
        [34.03325772299111,74.08187704441838],
        [34.04680410234127,74.13756838015831],
        [34.03144979830964,74.17669403431877],
        [34.01702769826524,74.19741936537008],
        [34.016320235384704,74.21650081067368],
        [34.026444789374125,74.21470711961325],
        [34.02308029640621,74.23325724803769],
        [33.99780199810506,74.25947490149383],
        [33.96915041265718,74.27755502715499],
        [33.90557908115079,74.27767765539446],
        [33.869749817508854,74.23317196754846],
        [33.839229312347,74.18285375985212],
        [33.82729065403279,74.12672303230238],
        [33.82790587913075,74.07349856467906],
        [33.822099719795574,74.05581352583494],
        [33.80518683273673,74.03638473103544],
        [33.78851398209359,74.02654659399278],
        [33.776671867720765,74.00915218114213],
        [33.76193017943644,74.01137502279393],
        [33.74296036416459,74.00173328189314],
        [33.74235620113507,73.98569776915281],
        [33.71746501495081,73.95817459326602],
        [33.7060298547439,73.96655982670762],
        [33.70571588100715,73.98192008397301],
        [33.692758061971986,73.98067173931355],
        [33.65523530687341,73.99328760161339],
        [33.65582870627901,73.98390172916027],
        [33.64190685915539,73.97974712457565],
        [33.62241880615727,73.99797072142326],
        [33.60002984872604,74.01851932621183],
        [33.577592163252845,74.03104510400146],
        [33.57597632996934,74.05316153954794],
        [33.58388137624533,74.07937559909695],
        [33.57157745608621,74.09529011809063],
        [33.57462756268815,74.10876927144699],
        [33.551040274943674,74.14607975619862],
        [33.53665604797902,74.15687061912384],
        [33.529222137598616,74.15935979065878],
        [33.5120972499967,74.15661769432771],
        [33.4809943115536,74.18949613269305],
        [33.470915863953266,74.19190322243097],
        [33.47004955552846,74.18326652445009],
        [33.45037779645279,74.18081505410991],
        [33.41180325596643,74.17819712911339],
        [33.40779981009964,74.18880383231279],
        [33.38923935078503,74.1860417398513],
        [33.345078172552654,74.16598625529706],
        [33.33707213919496,74.16591302603416],
        [33.33343653174772,74.15479600905005],
        [33.32615870982302,74.15443215379378],
        [33.3217948909428,74.14360576280305],
        [33.304224718897686,74.1264228920362],
        [33.29977050425309,74.10633376134294],
        [33.285842793976585,74.09205715050248],
        [33.28017518798352,74.06906175988283],
        [33.28000378330332,74.0557929646683],
        [33.286149367806246,74.04949919327765],
        [33.27900382961379,74.04504917828875],
        [33.26616963949263,74.02510536059225],
        [33.269373885671236,74.01765846057893],
        [33.258145380388285,74.01666861355329],
        [33.251291258011236,74.00986624667487],
        [33.2368539259125,74.00178340677859],
        [33.2171774759713,74.00847229042412],
        [33.20551547363257,74.01719331311027],
        [33.20066213878552,74.03172685564908],
        [33.20210275555694,74.04756821515511],
        [33.19728003083938,74.06340957466115],
        [33.174441345152744,74.08695476587835],
        [33.14143783464097,74.1499325741628],
        [33.12135343266127,74.1619801580834],
        [33.1075379083544,74.16314018255977],
        [33.09600392548434,74.17824989257493],
        [33.08809965839299,74.18036096785895],
        [33.075634380992774,74.16886259383915],
        [33.028728444312776,74.31630458105842],
        [33.00293896590547,74.32231599650743],
        [32.96131218206674,74.33741950389427],
        [32.95193873276327,74.33902642824448],
        [32.9366963891496,74.31265768509155],
        [32.92558068859195,74.31377335872682],
        [32.91763674033792,74.32968410536736],
        [32.922021596413856,74.3490918104459],
        [32.91141739139959,74.38510965385149],
        [32.904679543263455,74.40002295570424],
        [32.897941695127315,74.414936257557],
        [32.88443606439627,74.42006336639524],
        [32.8691684516842,74.41400020823329],
        [32.85214333111361,74.42075746747174],
        [32.83864581322849,74.41912202646094],
        [32.82690232723101,74.43508970645715],
        [32.80634294240035,74.4433640778881],
        [32.79399252316103,74.44928267879436],
        [32.787083025793564,74.46125320433387],
        [32.777233651421014,74.46203346287336],
        [32.771059123877905,74.48037938045242],
        [32.76459121147079,74.51042699457929],
        [32.74518409579334,74.5124989412046],
        [32.74793567069591,74.54880855770593],
        [32.75891700100141,74.55736141806648],
        [32.75676124228842,74.57597537784],
        [32.752840950216914,74.58829481242708],
        [32.75245427207247,74.63617376739833],
        [32.77698094498371,74.6309684462293],
        [32.78504190657658,74.65793514268775],
        [32.8193934825266,74.6405305834769],
        [32.83140603595652,74.67523070498538],
        [32.84093783150785,74.69432924496007],
        [32.83519090403381,74.7057344763695],
        [32.624845004334,74.65554857911494],
        [32.478429549769544,74.71781290376174],
        [32.485404862701095,75.02530718711013],
        [32.41407667358672,75.20321118357279],
        [32.263708957750296,75.38621829989012],
        [32.13564187336887,75.29481844739729],
        [32.06917437402087,75.16284252964817],
        [32.056797200417236,74.92938496649013],
        [31.972499923688353,74.82840110828076],
        [31.899301291225527,74.62643339185934],
        [31.754253337069066,74.5477701973337],
        [31.714959601787996,74.49209138275522],
        [31.551521312416618,74.60435841743728],
        [31.502047716687116,74.58493830806623],
        [31.43852843279914,74.64774566379685],
        [31.355819293269832,74.5497357114164],
        [31.132287138292398,74.51635192581412],
        [31.12690202546168,74.69388685724249],
        [31.017068401555882,74.55123192908239],
        [30.738487612757837,74.26966711486816],
        [30.461208315079112,73.93634172837882],
        [30.18735916328555,73.93612325701824],
        [29.95197106064721,73.42507564599262],
        [29.03772630239383,72.94812379286886],
        [28.83590674798005,72.39074045506715],
        [28.413665338298678,72.2181711714027],
        [27.974792387159095,71.89601302826922],
        [27.84755082802348,71.27467716619776],
        [27.716641392408533,70.79035944044578],
        [27.919499231611127,70.69498010830074],
        [28.03974023629428,70.48124101981814],
        [27.907280807082188,70.3249529918196],
        [27.856036388986354,70.16866496382107],
        [27.49137235155263,69.93418006312145],
        [27.17155674380286,69.55713195849484],
        [26.741520729630633,69.51542889924765],
        [26.65986567803818,69.79970956785274],
        [26.520058507474417,70.1844275115117],
        [26.020976227556925,70.08060727947075],
        [25.68775796686503,70.30959487507135],
        [25.703099967414158,70.62968752110626],
        [25.36705083742384,70.69536220833314],
        [24.727865721876597,71.12545709730607],
        [24.59742184744374,70.99748863492192],
        [24.401926629671404,71.08625807306046],
        [24.202834147465772,70.64332549610698],
        [24.44730237136926,70.5617751889801],
        [24.313112953955937,70.21483118117689],
        [24.188977972153616,69.99427950644164],
        [24.21152778076184,69.70478655912324],
        [24.282266910187417,68.93748198114275],
        [24.06450032592609,69.20429649187984],
        [23.86771624200489,69.03448294293443],
        [23.836904179623303,68.74589595845059],
        [23.58282189781616,68.45582294477788],
        [23.12621502038627,68.6728249839953],
        [22.798543434429547,69.2905542089544],
        [22.841225131810333,69.84955012157367],
        [22.98882257813314,70.17970063499925],
    ],

    //singapore
    [
        [1.2656725428063709,104.13359075594332],
        [1.3579052001222607,104.07862232773334],
        [1.4068148813333252,104.10016771975938],
        [1.4370978026364298,104.06847785233276],
        [1.4482479539072663,104.03333513706787],
        [1.4221451537502716,103.99286889585812],
        [1.4323768566243569,103.93270933066452],
        [1.431964809609017,103.89118210627691],
        [1.4634838355668425,103.85764017080965],
        [1.4790375622768863,103.812120301964],
        [1.4512752282284076,103.76314380674832],
        [1.4581046246472837,103.71150554855868],
        [1.3773009884293828,103.64682459528382],
        [1.2812710753594772,103.60164849980922],
        [1.2598672231096195,103.57506780761322],
        [1.1958853438711117,103.56711945622595],
        [1.186174750859891,103.65747757978707],
        [1.1325542513379787,103.73319600699898],
        [1.2217333297001218,103.91875368512177],
        [1.2649919389301125,104.03948103539642],
        [1.2656725428063709,104.13359075594332],
    ],

    //indonesia
    [
        [-5.882852019776067,106.05566154942602],
        [-5.887586791152164,106.03046490238987],
        [-5.908107552824513,106.00703157461828],
        [-5.933012852408225,105.99505982205866],
        [-5.961425891219296,105.99366798508248],
        [-6.0382459811456854,105.94049101705794],
        [-6.071105591469202,105.88666337122137],
        [-6.182429682011744,105.84035096607951],
        [-6.323605999876573,105.8334935745886],
        [-6.42220057261912,105.83006487884316],
        [-6.472251813305014,105.80033284066376],
        [-6.509226639498918,105.77999485335502],
        [-6.520067519435418,105.73711114395772],
        [-6.519707727718918,105.69046981421504],
        [-6.493214670126633,105.65510134551243],
        [-6.557433179868903,105.61902920218509],
        [-6.625384692603337,105.61301802164104],
        [-6.675435597277364,105.56717707742405],
        [-6.842727812629974,105.45294946690183],
        [-6.868387245321866,105.53157815340079],
        [-6.849277662748797,105.60269159920503],
        [-6.844635105718709,105.77122183324735],
        [-6.820425965998638,106.05191799611862],
        [-7.002527030859838,106.36637647395656],
        [-7.002527030859838,106.56478479925966],
        [-7.27368811232806,106.37012002726391],
        [-7.370226806600272,106.41129911364715],
        [-7.425912655855555,106.76319312456275],
        [-7.492726360311011,107.40334074016073],
        [-7.678266371199626,107.78892786342203],
        [-7.811804849076154,108.28682045333346],
        [-7.808096020011206,108.47774167201987],
        [-7.759878251872749,108.50394654517356],
        [-7.715364633601553,108.48522877863468],
        [-7.6634261585411,108.60876603778621],
        [-7.696815908195333,108.78845659655042],
        [-7.741331482066883,108.80717436308936],
        [-7.782133295610919,109.05424888139038],
        [-7.722783896092011,109.0093262416998],
        [-7.678266371199626,109.129119947542],
        [-7.717568171176055,109.39561511836615],
        [-7.769499996886083,109.4218199915178],
        [-7.7620815568172645,109.48920395105466],
        [-7.851094161469746,109.90099481489045],
        [-7.999406033050818,110.24165816588203],
        [-8.107011288271863,110.46275526206563],
        [-8.203359387458775,110.74352176013508],
        [-8.207064618954888,110.88577678582362],
        [-8.247819881222668,111.0055704916677],
        [-8.229295281676144,111.09167221774152],
        [-8.277457433994286,111.15905617727833],
        [-8.247819881222668,111.3050547562741],
        [-8.258934224368218,111.44356622865513],
        [-8.307092756586854,111.46228399519407],
        [-8.373764038134894,111.70935851349509],
        [-8.321909579140495,111.7917166862635],
        [-8.255229478070234,111.81043445280045],
        [-8.329317780307932,112.24094308317507],
        [-8.444128563509636,112.67885131500543],
        [-8.3885800037758,112.92218227999916],
        [-8.292277047727637,113.0419759858433],
        [-8.277459107236865,113.21792299130016],
        [-8.318207099076133,113.36017801698858],
        [-8.39228348876334,113.3938699967569],
        [-8.37573744686729,113.45553333568142],
        [-8.416628256468911,113.4931095391575],
        [-8.451939572761404,113.63965673271457],
        [-8.518836346620091,113.72608200071113],
        [-8.580148115732328,113.90832658756972],
        [-8.648879841246355,114.22960312729242],
        [-8.63216261640143,114.31602872893347],
        [-8.736169768325226,114.34233207136737],
        [-8.758453253021898,114.46633354284006],
        [-8.775164990545278,114.57530453292105],
        [-8.71945628060277,114.60912311604972],
        [-8.65630970129969,114.55088000066183],
        [-8.578290481024666,114.41560566814695],
        [-8.444506032374136,114.38554470536565],
        [-8.531842849693405,114.35924136293181],
        [-8.383172612437832,114.35172612223693],
        [-8.225148193874674,114.39305994606048],
        [-8.145182171858906,114.40057514895761],
        [-8.052178551626483,114.43439373208633],
        [-7.925659392807887,114.42312087104415],
        [-7.871690823280005,114.47572755591187],
        [-7.797239892549214,114.45693945417281],
        [-7.748839674706176,114.34045322339705],
        [-7.6985720212591815,114.23899747401089],
        [-7.715328570102102,114.19390602983793],
        [-7.702295756080957,114.1093595720161],
        [-7.603605772237259,114.03796478541125],
        [-7.691124453484235,113.93275141567767],
        [-7.685313319878716,113.84142776580421],
        [-7.7393059583503,113.77003297919936],
        [-7.705794097085956,113.70427462311653],
        [-7.731859107862888,113.67045603998787],
        [-7.707255835827681,113.57302130816117],
        [-7.716564920557815,113.55799082676958],
        [-7.696084663975611,113.48659604016473],
        [-7.7407675811479635,113.43586816547162],
        [-7.738905887307055,113.38326148060582],
        [-7.774276662359526,113.33441241608551],
        [-7.766830430676265,113.30247264313147],
        [-7.777999728666174,113.28744216173982],
        [-7.722150273041052,113.22544142600441],
        [-7.7314590297564365,113.13713734783522],
        [-7.651397037285804,113.03943921879647],
        [-7.6569832472611665,112.98683253393068],
        [-7.627189282550617,112.94737752027987],
        [-7.566536115278339,112.87282393737587],
        [-7.482717722900773,112.81645963216067],
        [-7.478992085837632,112.84276297459462],
        [-7.337394452352456,112.8446417847693],
        [-7.249804883926217,112.82585368303018],
        [-7.2032077338046605,112.7676105676423],
        [-7.216255419980939,112.70936745225441],
        [-7.199479754462914,112.6586395775613],
        [-7.1547416168174465,112.66615481825613],
        [-7.0886142966105865,112.63551700816168],
        [-7.038271417775235,112.6486686793786],
        [-6.97113908980549,112.6035772352077],
        [-6.9226486432365135,112.59606199451082],
        [-6.898401549129645,112.6186077165973],
        [-6.877883807319762,112.60169842503296],
        [-6.8722879056243045,112.57351627242639],
        [-6.8499036406623475,112.55472817068727],
        [-6.864826600907506,112.52278839773322],
        [-6.879749093237962,112.55097055033991],
        [-6.905862325900458,112.51151553668905],
        [-6.862961256444578,112.42321145851975],
        [-6.874153213511022,112.23157282079171],
        [-6.898402076696854,112.14890522386156],
        [-6.892806416981841,112.088783298301],
        [-6.88907594046735,112.05496471517216],
        [-6.825653362510465,112.00987327099932],
        [-6.767819567659885,111.96853944717566],
        [-6.779013747910753,111.90278109109295],
        [-6.8021562630300565,111.8494349898067],
        [-6.779768717996632,111.77804020320178],
        [-6.766708836229469,111.71040303694434],
        [-6.636090701752252,111.55446179251771],
        [-6.6304920025454095,111.49058224660769],
        [-6.679011943921793,111.44736961261145],
        [-6.699925688132396,111.36083739236517],
        [-6.684997624618816,111.28944260576031],
        [-6.683131584603899,111.23119949037238],
        [-6.664470793118596,111.18610804619937],
        [-6.634612050402964,111.12786493081154],
        [-6.548758110494262,111.07713705611843],
        [-6.468489979541715,111.04895490351186],
        [-6.418082533084913,111.04707609333713],
        [-6.4050131199100235,110.91368057099697],
        [-6.408747272138726,110.83664935386997],
        [-6.425550618667444,110.81222482161076],
        [-6.42368360754412,110.78967909952434],
        [-6.449821139077002,110.71264788239728],
        [-6.503958874830261,110.67319286874852],
        [-6.561823798039654,110.65440476700945],
        [-6.643943102314509,110.64125309579248],
        [-6.731411098401367,110.59005076542485],
        [-6.731411098401367,110.54683813142663],
        [-6.766861116363216,110.54871694159931],
        [-6.785517976130066,110.5712626636859],
        [-6.940341831567551,110.47732215499451],
        [-6.949666955185748,110.4002909378695],
        [-6.945936927924052,110.33077496143738],
        [-6.916095646135048,110.27253184604945],
        [-6.865734212057859,110.21804635100892],
        [-6.847080474285562,110.17295490683603],
        [-6.899309096089723,110.07713558797207],
        [-6.927286348175457,109.99822556067232],
        [-6.9067699826869955,109.84416257018256],
        [-6.860138291558641,109.6807060850611],
        [-6.834022547968786,109.57361390515285],
        [-6.781786786104107,109.51724959993965],
        [-6.848946005058309,109.42143028107358],
        [-6.8750609310023805,109.27488308751646],
        [-6.848946005058309,109.16215447708805],
        [-6.8414843348082,109.10579017187285],
        [-6.768726958979855,109.07385039891886],
        [-6.804174237226178,108.98930394109703],
        [-6.817233101951572,108.90287867310059],
        [-6.79298064191461,108.83524150684309],
        [-6.742606245421101,108.82960507632293],
        [-6.792981261332741,108.79014930380441],
        [-6.811637108564341,108.7488154799808],
        [-6.807905997099525,108.69808760528775],
        [-6.766861859982882,108.67178426285392],
        [-6.761264661402066,108.60038947624906],
        [-6.701557186790168,108.5646920829476],
        [-6.559722747552115,108.54590398120854],
        [-6.481323176294396,108.53838874051377],
        [-6.442118810698915,108.45572109286667],
        [-6.380026240315118,108.39911505074008],
        [-6.3109361553295145,108.36341765743668],
        [-6.2567780202903265,108.35402360656911],
        [-6.254910398001826,108.31456859291836],
        [-6.232498410648844,108.20559760283732],
        [-6.271718758155288,108.21123403335747],
        [-6.322140581513196,108.14735448744932],
        [-6.327742703600379,108.10038423310374],
        [-6.260513244841988,107.94444298867711],
        [-6.228762986247034,107.89371511398394],
        [-6.176464260856875,107.89747273433142],
        [-6.206349882203213,107.86741177155005],
        [-6.185803699132151,107.83922961894348],
        [-6.22315979981073,107.76595602216389],
        [-6.230631757434352,107.70771180274295],
        [-6.230631757434352,107.6644991687466],
        [-6.18580475488497,107.61752891440096],
        [-6.174597409197958,107.5273460260571],
        [-6.120425235876965,107.4540724292795],
        [-6.012064458899999,107.38267764267471],
        [-5.961613261795492,107.3225557171121],
        [-5.970956427258997,107.21734234737852],
        [-5.972825041238423,107.13091707938196],
        [-5.987773723363901,107.09146206573314],
        [-5.941057738069489,107.10085611660071],
        [-5.92050144688875,107.0144308486062],
        [-5.93545155283897,106.99000631634698],
        [-5.956007286131026,107.0200672791263],
        [-5.998984966560911,107.00503679773658],
        [-6.027012065067936,106.99376393669439],
        [-6.055037716147098,106.97309702478259],
        [-6.08679836255466,106.95243011286885],
        [-6.107349372981744,106.85285284964118],
        [-6.103613066707581,106.81151902581757],
        [-6.099876734378327,106.74763947990755],
        [-6.040091886577386,106.70442684591126],
        [-5.99338037764953,106.63115324913167],
        [-6.032618315115741,106.60860752704531],
        [-6.0288814907426485,106.56351608287429],
        [-6.012065462762834,106.53345512009298],
        [-6.040091886577386,106.50151534713893],
        [-6.038223503377154,106.45266628261857],
        [-6.0101969830970035,106.3981807875781],
        [-5.976563253993291,106.38502911636112],
        [-5.957876955009198,106.34933172305972],
        [-5.9784318488342905,106.32678600097324],
        [-5.963482911576648,106.27230050593272],
        [-5.961614265751081,106.24223954315141],
        [-5.9989859704488,106.23472430245658],
        [-6.021407764794418,106.19339047863298],
        [-6.015802402841686,106.13702617341784],
        [-5.984037595059036,106.10884402081126],
        [-5.944796167165819,106.10696521063653],
        [-5.903683098253538,106.0900559190722],
        [-5.882852019776067,106.05566154942602],
    ],

    //malaysia
    [
        [1.3521203236196584,103.61625689191425],
        [1.3706844651694325,103.63623026204664],
        [1.3910585208701036,103.65202928835288],
        [1.4407282007287279,103.67331204630779],
        [1.4603879685361107,103.72641043547878],
        [1.4564560286589057,103.763775968598],
        [1.4820135144017996,103.82080757178346],
        [1.4289322584572233,103.90340506604872],
        [1.4308982530570233,104.00173541636576],
        [1.4367962267337617,104.07646648260624],
        [1.4033741763830818,104.0961325526684],
        [1.3601214038770024,104.08236630362381],
        [1.3581553501563945,104.28296021827043],
        [1.542957088398495,104.2770603972507],
        [1.8378194400003736,104.15709736986423],
        [1.8810620111267724,104.10203237368808],
        [1.9439583763952157,104.12169844375023],
        [2.2093211841277878,103.95853722955292],
        [2.248623563893304,103.96050383656018],
        [2.246658469892708,103.98213651362965],
        [2.2938199908230814,103.95263740853528],
        [2.3174001701872697,103.9624704435654],
        [2.3429449212032125,103.9369045524835],
        [2.488344477501954,103.81497491809182],
        [2.509956578916217,103.84054080917372],
        [2.5865784017083513,103.8189081321043],
        [2.6337280007397226,103.74417706586388],
        [2.671053502480831,103.63404707350946],
        [2.8125537994877305,103.48302164804562],
        [2.942186195272413,103.43188986588194],
        [3.230657128689444,103.44324813775154],
        [3.3504223519900336,103.42554867469448],
        [3.458394841176343,103.4471813517639],
        [3.4642838995276293,103.474713849853],
        [3.5270982295195097,103.47668045685828],
        [3.7057030312091968,103.3291849313847],
        [3.7763500196190307,103.32721832437744],
        [3.819282098307511,103.37835035000865],
        [3.917388412006261,103.36655070797133],
        [3.9683991676548516,103.40391624109259],
        [3.964475374709224,103.43341534618679],
        [4.093950599433597,103.38425017102833],
        [4.130007481167567,103.40739976123797],
        [4.1742370770203365,103.41517975959027],
        [4.175013012677667,103.43929775448606],
        [4.184324180699534,103.44552175317017],
        [4.216912395389926,103.43851975465196],
        [4.240964725475251,103.45018975218119],
        [4.261137070346038,103.47275174740719],
        [4.327857196837755,103.49220174329037],
        [4.393108065074003,103.45409718107271],
        [4.424911661403911,103.45643118057995],
        [4.469899779403576,103.44398318321328],
        [4.509456016403149,103.45020718189744],
        [4.517987472081032,103.46732317827502],
        [4.549010099711893,103.47354717695754],
        [4.638192791853385,103.43775900335999],
        [4.6591296860954685,103.44709500138373],
        [4.689370765211251,103.45020700072655],
        [4.757602223437388,103.42297700648788],
        [4.7738837347640555,103.42297700648788],
        [4.783187282129347,103.43853700319568],
        [5.395109781654099,103.1112904793568],
        [5.4584536310833585,103.03761474122365],
        [5.539568427537191,102.95500861059014],
        [5.535124068067333,102.93379892840102],
        [5.604007869197034,102.81435492870031],
        [5.66066426454681,102.75295848025706],
        [5.7784026385293,102.62904887547427],
        [5.857251296329878,102.52300142942346],
        [5.9460818859745075,102.45379088754049],
        [6.072640644614765,102.39462703722234],
        [6.152557211067517,102.35555657003005],
        [6.184742442713144,102.30755571033859],
        [6.225803513316592,102.21043769189009],
        [6.225803513316592,102.16355313126058],
        [6.210267268377436,102.15462273876011],
        [6.2446683356632064,102.08876109406549],
        [6.226913227526538,102.07759810343879],
        [6.170314821917415,102.08206329969016],
        [6.150337467961123,102.09210999125366],
        [6.08263086719235,102.05303952406143],
        [6.031567785846278,101.9782474868664],
        [6.004929353968592,101.96820072432865],
        [5.978284531644306,101.94140954682621],
        [5.953858971088906,101.94252584588816],
        [5.9460869738668265,101.92801395807436],
        [5.913887531485628,101.92243246276104],
        [5.9094460817509855,101.93471175244969],
        [5.868360985741006,101.94140954682621],
        [5.842820074104267,101.91350207026068],
        [5.843930572786817,101.88782719182018],
        [5.813946364907935,101.87443161199195],
        [5.793955985840398,101.8610360232401],
        [5.763969090986819,101.81973295792397],
        [5.732869897126648,101.82419815417416],
        [5.759526453109942,101.78066249073169],
        [5.787292368757946,101.77173209823127],
        [5.788402977062759,101.72261493947661],
        [5.753973106906031,101.70475415447464],
        [5.757305121140419,101.69024226666085],
        [5.7917347888793245,101.67461407978396],
        [5.8206096669069325,101.65675329478194],
        [5.873913231614196,101.65786959384394],
        [5.876134103088589,101.6288458182164],
        [5.930542678596652,101.58531015477399],
        [5.893072145561305,101.50139804204434],
        [5.871974151018463,101.48018835985403],
        [5.870863708094021,101.42548970578605],
        [5.870863708094021,101.39311703297028],
        [5.829775767678981,101.37079105171807],
        [5.8086753845447845,101.32279019202542],
        [5.81311763625763,101.27925452858415],
        [5.762029631221139,101.25246335108051],
        [5.715380045613799,101.25916114545703],
        [5.686051786157321,101.23513614777085],
        [5.689354345737911,101.2192054326946],
        [5.676143993735693,101.22252433166915],
        [5.660291171778127,101.21323141454064],
        [5.651043491457401,101.19398180048842],
        [5.645759036375495,101.16875816828366],
        [5.63452940939888,101.16942194807746],
        [5.610748306576099,101.1349053987442],
        [5.641135098651503,101.12760382099981],
        [5.668217637175147,101.12096602305212],
        [5.6867122995903685,101.1050353079745],
        [5.701903899092798,101.09375105146177],
        [5.715113658749303,101.07914789597453],
        [5.728983578345819,101.06454474048724],
        [5.744834502524668,101.06056206171746],
        [5.738890457590287,101.03600220930656],
        [5.763326689750116,101.01874393463987],
        [5.784459880308532,100.99152896304952],
        [5.809554518302065,100.98356360551145],
        [5.836629057647855,101.01077857710175],
        [5.8726655118210545,101.00913168725901],
        [5.899736993418458,101.02705374172092],
        [5.913602362763086,101.02638996192707],
        [5.91756383306344,101.03634665884942],
        [5.910961366800734,101.05360493351606],
        [5.918224075354146,101.06024273146517],
        [5.909640864093575,101.08546636367134],
        [5.939351411751176,101.0987419595682],
        [5.952555585622349,101.0954230605937],
        [5.966419626708472,101.11865535341565],
        [5.981603650353534,101.1246293715696],
        [5.991506047391027,101.10737109690155],
        [6.008669776589684,101.11135377567126],
        [6.041015332451352,101.1133451150555],
        [6.051290222387436,101.09999709931111],
        [6.0803330120174905,101.11327269520802],
        [6.098153947957158,101.1278758506952],
        [6.115974291730225,101.11991049315714],
        [6.115974291730225,101.10729867705402],
        [6.127194203613101,101.09999709931111],
        [6.136433953969515,101.07941992566987],
        [6.143693645450398,101.06083409141286],
        [6.16151246816716,101.07477346710499],
        [6.171411555738317,101.07543724690015],
        [6.179330692659406,101.09535064074618],
        [6.192528990779422,101.12721207090141],
        [6.203747284847154,101.11592781438742],
        [6.2394319384022054,101.10610712552881],
        [6.251309064663019,101.1120811436827],
        [6.261206463886822,101.09283152963053],
        [6.2592269990392,101.06495277824638],
        [6.246030375144642,101.03110000870691],
        [6.249329562333585,101.02247087137357],
        [6.248009889954616,101.01384173404028],
        [6.266485000126664,100.98994566142449],
        [6.279021309600367,100.98529920285955],
        [6.284959456155164,100.96870470798802],
        [6.271763482968225,100.96604958880874],
        [6.265165371071859,100.94679997475646],
        [6.243391010422798,100.94480863537234],
        [6.249281470821373,100.91591924589437],
        [6.234105036277484,100.90264364999615],
        [6.241363385911484,100.89202317327857],
        [6.249281470821373,100.89202317327857],
        [6.265117281015634,100.8721097794326],
        [6.245322443335155,100.8661357612786],
        [6.240043693455007,100.8721097794326],
        [6.2327853255482495,100.85551528455977],
        [6.251260973331469,100.84356724825187],
        [6.292828791952445,100.83560189071369],
        [6.311962068306983,100.8488774866106],
        [6.359551376055464,100.82290045986218],
        [6.404408683525361,100.82157290027175],
        [6.442666214410295,100.81227998314324],
        [6.450581206060093,100.7903752499131],
        [6.463772584025449,100.7764358742196],
        [6.455198227443361,100.7691342964767],
        [6.457836506479154,100.75320358139902],
        [6.4723467952356515,100.74391066427046],
        [6.490154307031432,100.73793664611657],
        [6.500047097201161,100.74125554509118],
        [6.511258690779755,100.73528152693723],
        [6.496749522087043,100.72930750878322],
        [6.4730063439161825,100.70939411493731],
        [6.466186407455595,100.68722131604954],
        [6.452995092400826,100.68257485748461],
        [6.45035678814601,100.66067012425435],
        [6.442441792991943,100.65403232630524],
        [6.4490376308669255,100.64274806979256],
        [6.458931226727046,100.64606696876712],
        [6.461569486304754,100.6148693184071],
        [6.471462837138759,100.60225750230398],
        [6.483334602337891,100.57570631050879],
        [6.4958656067797875,100.56243071461199],
        [6.485313202708795,100.5478275591247],
        [6.489390360877323,100.51886017099588],
        [6.501325716043496,100.50134199593157],
        [6.5127634988867555,100.49283316804275],
        [6.52221190552217,100.49483524519377],
        [6.523206464284399,100.46680616509155],
        [6.532654674024215,100.45829733720484],
        [6.5291737753894665,100.44728591287821],
        [6.5177363666731765,100.42125890992702],
        [6.528179228476972,100.40474177343816],
        [6.536417831816308,100.40238469310617],
        [6.533711105216511,100.39619280930583],
        [6.534941437303715,100.38777184733766],
        [6.539616671628764,100.38702882128177],
        [6.539124543759456,100.38405671705596],
        [6.542077303710272,100.38232298959133],
        [6.545030046211181,100.37315900156733],
        [6.538386351044949,100.36870084522957],
        [6.54576822910856,100.36151826002066],
        [6.561761923712936,100.35458335016449],
        [6.573572322475798,100.35185892129124],
        [6.57209603796241,100.34492401143308],
        [6.580055065438287,100.34180512902697],
        [6.585221959282251,100.33437486846748],
        [6.585468000504505,100.32545855579406],
        [6.5948174765399585,100.31877132128852],
        [6.604166776196124,100.3165422431207],
        [6.605888996348099,100.30613987833323],
        [6.611547677488943,100.30514917692676],
        [6.613761926392911,100.3165422431207],
        [6.621634731005926,100.32595390649732],
        [6.635411836820893,100.32149575015956],
        [6.6346737873433455,100.31629456776795],
        [6.640086124491546,100.31703759382395],
        [6.642300245265005,100.32149575015956],
        [6.654199348624175,100.31955344396385],
        [6.660595486638556,100.3267360291726],
        [6.664777531802287,100.32648835381991],
        [6.667975542273425,100.30716967636118],
        [6.68002939409871,100.30073011720833],
        [6.678553428189076,100.29701498692646],
        [6.689869052985088,100.29008007707029],
        [6.701184415660549,100.29230915523812],
        [6.703644242384527,100.29528125946399],
        [6.711023648063289,100.29057542777349],
        [6.70782591932624,100.28710797284651],
        [6.706841998568038,100.27918236157961],
        [6.698970561000621,100.2655602172178],
        [6.711371065348416,100.25793952399738],
        [6.709157256960694,100.25249066625315],
        [6.696366167137171,100.24877553597332],
        [6.691991804522502,100.24218537638836],
        [6.693713716973534,100.22881090737928],
        [6.701831222412281,100.21915156864992],
        [6.710932507196318,100.21816086724135],
        [6.719295699997119,100.21395038625622],
        [6.719541674080247,100.2070154764001],
        [6.725690985696787,100.20404337217423],
        [6.717081927656835,100.18645842217995],
        [6.6969115382680116,100.17729443415396],
        [6.693959703970336,100.17110255035368],
        [6.682890166453973,100.17506535598602],
        [6.682644173883006,100.17878048626574],
        [6.670344387687848,100.17902816161842],
        [6.6548972219128615,100.18018792300177],
        [6.649977066048592,100.17449138990474],
        [6.648254999856405,100.16755648004653],
        [6.633494185188667,100.1685471814551],
        [6.632510115141514,100.16557507723132],
        [6.6083997857843855,100.16953788286372],
        [6.603479165843538,100.17696814342526],
        [6.597082286788435,100.18117862441039],
        [6.592407592162687,100.17721581877584],
        [6.583304112998675,100.18093094905765],
        [6.578383243933274,100.18514143004273],
        [6.571739993387766,100.18266467652222],
        [6.573708373174668,100.17795884483394],
        [6.567065060175352,100.17647279271995],
        [6.574938606584482,100.1705285842724],
        [6.570509752064552,100.1653274018787],
        [6.576660928243342,100.16260297300755],
        [6.580105553651578,100.15740179061385],
        [6.575184652901754,100.15616341385254],
        [6.562636135571978,100.15963086878168],
        [6.556617075229809,100.15334711866359],
        [6.548743239200633,100.15334711866359],
        [6.530042381051544,100.1570622489433],
        [6.522414198702236,100.1578052750013],
        [6.522414198702236,100.15409014471948],
        [6.506173165804142,100.1585483010573],
        [6.502728030678227,100.16077737922507],
        [6.495099433173905,100.1597866778165],
        [6.486978541021529,100.16003435316907],
        [6.486978541021529,100.15409014471948],
        [6.484025456871464,100.15409014471948],
        [6.48181063240699,100.15904365176056],
        [6.477134859930359,100.15656689824004],
        [6.469259776797699,100.14864128697508],
        [6.464175577033757,100.14438414037085],
        [6.452116496997476,100.14760391994736],
        [6.448178776019375,100.14289808825896],
        [6.4447332450729675,100.13571550305016],
        [6.432427586428574,100.13472480164148],
        [6.423321207165017,100.12159800798304],
        [6.407792504200231,100.12408968398319],
        [6.399177949329058,100.12879551567158],
        [6.395239818708632,100.12631876215107],
        [6.392532336307056,100.1325106459513],
        [6.356102999836324,100.14588511496248],
        [6.317841042110771,100.15953655745079],
        [6.287567061956381,100.17076249498086],
        [6.265656089486683,100.18289858723068],
        [6.2535923521882495,100.19156722455142],
        [6.248914501581737,100.19478700412787],
        [6.238620953272971,100.21327043288989],
        [6.218185241214485,100.23580888992507],
        [6.187849802390957,100.25590632249146],
        [6.142192429693409,100.27567306905507],
        [6.113872519168638,100.27963587468736],
        [6.0896690497983315,100.28428070167371],
        [6.056519440579592,100.3227461294735],
        [5.955783187724748,100.35351847171262],
        [5.876711397753681,100.35480065263982],
        [5.793021313483422,100.36914594943516],
        [5.702444156637625,100.35119541646179],
        [5.578674829375345,100.34222014997516],
        [5.469964591188827,100.37822086803408],
        [5.420185178406669,100.37309214432781],
        [5.429120248079428,100.31924054540912],
        [5.467411901210156,100.30641873614206],
        [5.482727877614451,100.25384931815057],
        [5.462306488603517,100.21410170942346],
        [5.4763462684736055,100.17435410069771],
        [5.434225942734599,100.1794828244054],
        [5.30784738065384,100.1974333573774],
        [5.286143388556795,100.1794828244054],
        [5.2810364562174215,100.21410170942346],
        [5.258054740590325,100.28077511760921],
        [5.324444035874933,100.302572193363],
        [5.3921026711416005,100.32693363096848],
        [5.4048676086353,100.35257724950128],
        [5.38061399786416,100.37052778247465],
        [5.337210388935091,100.40642884842134],
        [5.282313193248754,100.41796847676113],
        [5.266992175455826,100.40771102934724],
        [5.213365646047038,100.42437938139335],
        [5.163565481016079,100.41668629583387],
        [5.092050720054559,100.36027033506207],
        [4.9970986028944395,100.40536657802511],
        [4.995821294152009,100.42075274914532],
        [4.948559126073945,100.42203493007116],
        [4.923010600613125,100.4425498248977],
        [4.929397824173037,100.4707578052836],
        [4.91534585139118,100.49896578566944],
        [4.902571073461857,100.4835796145507],
        [4.869355509013289,100.4835796145507],
        [4.8655228381086175,100.51948068049603],
        [4.884685974048352,100.54384211810287],
        [4.791420264773919,100.56179265107625],
        [4.788864859619295,100.62205515462847],
        [4.758199254446112,100.57846100312236],
        [4.672278804758463,100.59681330208855],
        [4.679946283581174,100.6506649010073],
        [4.63394015546244,100.60194202579493],
        [4.572593998406504,100.58527367374876],
        [4.5562861949962326,100.62102040715018],
        [4.566511153816407,100.66076801587593],
        [4.532001335591019,100.6248669499306],
        [4.4361321231636595,100.61332732159076],
        [4.42334861529028,100.58768370305665],
        [4.314679954020491,100.56075790359796],
        [4.303177062280156,100.57999066522797],
        [4.230295307941859,100.59024811264061],
        [4.253311345229136,100.56460449410781],
        [4.252032694496265,100.53511433279459],
        [4.214950902914737,100.54280741835544],
        [4.1893762220556425,100.57486194152028],
        [4.218787032606656,100.58383720800697],
        [4.2085573113711945,100.60178774098034],
        [4.15740669565966,100.62743135951314],
        [4.168915875055916,100.67743641565278],
        [4.1215992871560445,100.72487710993795],
        [4.080674560739581,100.76590689959096],
        [4.010042255959419,100.74923857376967],
        [3.9972517369587166,100.71077314596982],
        [3.9153877175665315,100.70436224133766],
        [3.8680564183686954,100.72231277430973],
        [3.8552637201701714,100.76590692581578],
        [3.859101549868811,100.81975852473579],
        [3.7925767285614853,100.80821889639606],
        [3.777224116276358,100.83001597214837],
        [3.7593123920823075,100.88643193292023],
        [3.6856115670075127,100.93573407472417],
        [3.661300182404034,100.99215003549602],
        [3.5985993652881234,101.05369471997545],
        [3.5198324474827274,101.08880242508081],
        [3.4609615349971676,101.12085694824714],
        [3.400807041818439,101.16316891882599],
        [3.3444887943021797,101.19265908013915],
        [3.3223014820599985,101.24146763006763],
        [3.252666054777663,101.29765530310732],
        [3.186894843946149,101.3073428329422],
        [3.12117299489438,101.31468197933975],
        [3.072806089850232,101.35924461657675],
        [3.004121326815067,101.36408838149418],
        [2.9915227306795913,101.39579456909587],
        [2.9373451616022948,101.36188821467567],
        [2.9063853673441855,101.31829433042054],
        [2.870587047027982,101.28245047003253],
        [2.835755172247957,101.29601301180105],
        [2.8270470391565397,101.33185687218901],
        [2.8038250319337834,101.40354459296299],
        [2.7188375783164815,101.459798260318],
        [2.683033537255696,101.49370461474024],
        [2.642389842402892,101.58767365413365],
        [2.594002758116602,101.71167403601385],
        [2.5998093066637864,101.76979921502135],
        [2.555291756035075,101.79886180452411],
        [2.5291611686867412,101.79111178065699],
        [2.5123256070011593,101.8307519237377],
        [2.461434018411893,101.85525294668287],
        [2.414975973752533,101.85622169966473],
        [2.4275585190003,101.93469069132396],
        [2.4004575075415175,101.96181577486095],
        [2.3752918013097712,101.97537831662947],
        [2.3636767050348766,102.01412843596705],
        [2.3317346905543843,102.06837860304108],
        [2.2930161260355675,102.08000363884173],
        [2.2719242013358354,102.10471289112098],
        [2.264180248711398,102.12602545675855],
        [2.218683695937557,102.15702555222708],
        [2.214811583858676,102.1870568947158],
        [2.182866274670431,102.22193200211984],
        [2.182866274670431,102.25196334460651],
        [2.1615690240917758,102.32171355941472],
        [2.1393033972325384,102.36627619665177],
        [2.1160693550821037,102.43990142339351],
        [2.090898750020145,102.50480787328428],
        [2.049269558004738,102.54937051052326],
        [2.033189936496754,102.54298936686581],
        [1.9441183495313794,102.63114588835992],
        [1.851169076682794,102.70767737405134],
        [1.869565681525387,102.74061497548968],
        [1.8608515242018484,102.80745893134826],
        [1.814375296156129,102.88689667598936],
        [1.7921050117782187,102.88980293494103],
        [1.7407855048007406,102.94695936096264],
        [1.7406145761043632,102.99300337532054],
        [1.6970403076293508,103.08600366173215],
        [1.6350663295909982,103.15769138250812],
        [1.5895529667557184,103.27103548157146],
        [1.5353229716439216,103.36016075604755],
        [1.4501015117188842,103.40181717042549],
        [1.3852149323559928,103.41925472412754],
        [1.3290430227121135,103.44056728976517],
        [1.3396964157450668,103.41925472412754],
        [1.3077360991440514,103.41925472412754],
        [1.3183895837223787,103.45412983153165],
        [1.2631846779129887,103.50934875158953],
        [1.3106415994377159,103.51903628142446],
        [1.3329169891514852,103.55294263584466],
        [1.3336214775057158,103.57826870671755],
        [1.3446368875416965,103.59756792209362],
        [1.3521203236196584,103.61625689191425],
    ],
    //dubai
    [
        [26.059949150190846,56.083071593265345],
        [25.941951138222294,55.782762480594926],
        [25.783148992665502,55.448383720223745],
        [25.540356628070825,55.22546454664163],
        [25.422970001059568,55.02112197085944],
        [25.221469776964824,54.881797487370534],
        [25.08695042919537,54.74247300388163],
        [25.019635212176638,54.51026553140085],
        [24.88489405494545,54.43595914020764],
        [24.555847212076344,53.99940909194348],
        [24.53049994998176,53.795066516161256],
        [24.6403010683307,53.56285904368053],
        [24.74157036265892,53.534994146983195],
        [24.868041052214167,53.29349837560255],
        [25.061711555398247,53.23776858220796],
        [25.120594165495433,53.09844409871906],
        [25.330657357886324,53.05200260422319],
        [25.389409930809933,52.829083430641134],
        [25.13741256163034,52.65260575155625],
        [24.977544442380733,52.615452555959024],
        [24.98596374961268,52.4111099801768],
        [24.927016500578986,52.36466848567966],
        [24.724697863633935,52.33680358898232],
        [24.724697863633935,52.21605570329206],
        [24.623414857323226,52.09530781760296],
        [24.74157036265892,52.048866323107035],
        [24.75844057231255,51.853812046222316],
        [24.667863119890086,51.59128623224149],
        [24.4002491687328,51.42075063920592],
        [24.345881193900553,51.54438894415722],
        [24.26428545610628,51.59128623224149],
        [24.139848352040687,51.578496062763435],
        [22.93208482177667,52.57612931677417],
        [22.62547940222109,55.15346527537332],
        [22.712028186754736,55.21315273293558],
        [23.096897044308164,55.23873307189052],
        [23.39069764852465,55.400741885274755],
        [23.629176282463618,55.5584873088323],
        [23.72288530331538,55.5584873088323],
        [23.947627420503807,55.488157311269134],
        [24.061398694661676,55.73012571505177],
        [24.053275513159733,55.77816355992019],
        [24.025652851558817,55.79951371319379],
        [24.019152538682192,55.82620140478866],
        [24.045151817584014,55.899147761809616],
        [24.06464782329192,56.01479442538158],
        [24.22537668055331,55.95252314499737],
        [24.191299578003793,55.83331812254653],
        [24.249711886824798,55.75859258608321],
        [24.322689561531277,55.831538943106125],
        [24.437721479944557,55.81907156902136],
        [24.528396804615113,55.763917006395076],
        [24.573709698569715,55.767475533864825],
        [24.617388993448444,55.81195501985431],
        [24.636797118792188,55.79238404601929],
        [24.670754083312687,55.833305173129844],
        [24.76933853606849,55.83508435256837],
        [24.791187729698933,55.81729244096064],
        [24.91388316549552,55.81373408208174],
        [24.970346667394992,55.84931767087289],
        [24.967120878878234,55.908030592378225],
        [25.010661885561376,55.95606843724667],
        [24.997762454413177,56.00410628211506],
        [24.970346667394992,56.043248229787054],
        [24.949377530680394,56.043248229787054],
        [24.952603784196327,56.0574816653029],
        [24.905814839159447,56.04146905034662],
        [24.86869374487614,56.062819203622155],
        [24.899359798236034,55.9791977699625],
        [24.879992650244432,55.97741859052212],
        [24.815413569917098,56.03435233258878],
        [24.791187729698933,56.03613151202731],
        [24.75564793862472,56.05392330642391],
        [24.744337690355366,56.08061099801682],
        [24.73787422928021,56.13576556064314],
        [24.76372605713796,56.16423243167651],
        [24.784726706313407,56.201595199908155],
        [24.852550659808983,56.20871191766602],
        [24.860622465774895,56.263866480292364],
        [24.88322071915711,56.285216633567785],
        [24.889676603908683,56.320800222359026],
        [24.931631625774727,56.345708734513465],
        [24.97679799070731,56.32435858123799],
        [24.986474341250215,56.3688380672275],
        [25.036456683122438,56.361721349469576],
        [25.191111575576784,56.361721766680716],
        [25.361648354754664,56.34926751060351],
        [25.593187078371102,56.35427214654089],
        [25.60393786604382,56.26188514487626],
        [25.61871862078553,56.19781028888232],
        [25.64693134798523,56.19184983716238],
        [25.656334108780044,56.156087126839196],
        [25.73018713990723,56.16949814321006],
        [25.817408878294145,56.13969588460844],
        [25.860325241269635,56.166517917350006],
        [25.87775556557908,56.16204757856076],
        [25.892502292778232,56.18439927251163],
        [25.9434313535958,56.16204757856076],
        [25.974246144902438,56.20079051474238],
        [25.99032022346283,56.18439927251163],
        [26.02380082584702,56.17843882079154],
        [26.07199611452117,56.156087126839196],
        [26.053255856906773,56.12181452944847],
        [26.059949150190846,56.083071593265345],
    ],
    //japan
    [
        [34.3644204548561, 131.24991967742767],
        [34.371440017567394, 131.12000938603757],
        [34.37146007539326, 131.00990925074154],
        [34.324909287220606, 130.8969752509617],
        [34.27365577176491, 130.87718996515457],
        [34.23635509556394, 130.92523758628636],
        [34.17338302368147, 130.93371574582778],
        [34.075320811944906, 130.89410867718072],
        [34.007553438640414, 130.91671197243124],
        [33.944414194063626, 130.91956586067533],
        [34.01924130812259, 131.01541611884625],
        [34.06130449037617, 131.0492384206575],
        [34.00519146204067, 131.16477518963632],
        [33.96308553565164, 131.16474548097005],
        [33.94904583483928, 131.2098698396013],
        [33.94203357808962, 131.28605369329716],
        [33.98181646945707, 131.34815160664834],
        [34.054293804415835, 131.37921010319803],
        [34.0145419393985, 131.41297975034803],
        [34.030899360222534, 131.47782696447604],
        [34.00985307165787, 131.54268868453522],
        [34.05193707607046, 131.60194477197695],
        [34.0613148829288, 131.70922412513374],
        [34.06599879971296, 131.78257725349386],
        [34.005204270555524, 131.8699409654251],
        [33.92801489684369, 131.9883995552289],
        [33.89991699468138, 132.04198309397822],
        [33.95377939648661, 132.0842991997484],
        [33.958481343814086, 132.14916097025133],
        [33.974880920266514, 132.19991090423798],
        [34.04031982927262, 132.21682918164237],
        [34.11269764903156, 132.20556344786996],
        [34.17804743668046, 132.21402721863865],
        [34.245670130501, 132.22810414139087],
        [34.301600520350775, 132.28733220968184],
        [34.35980530182964, 132.3521746996705],
        [34.36906536795851, 132.43385722151783],
        [34.35044021922501, 132.50430324737528],
        [34.29459284492421, 132.50720210374914],
        [34.25499118059925, 132.54951014412165],
        [34.19437828171951, 132.53270991666824],
        [34.224694974757924, 132.60597170865566],
        [34.28017234803673, 132.78211231746127],
        [34.33589000774873, 133.01623385458697],
        [34.400285069414664, 133.16541286338622],
        [34.39451330519782, 133.33561558737233],
        [34.456001911299865, 133.49889685467275],
        [34.511545464445575, 133.6763749839701],
        [34.46770649945137, 133.8183626246843],
        [34.47941691870487, 133.91065690053256],
        [34.55254286782545, 133.99940367161196],
        [34.61385951182315, 134.11293719815671],
        [34.7130732206886, 134.27260617584324],
        [34.76854499967466, 134.4998285293816],
        [34.77148778382262, 134.71993705249395],
        [34.64598166507575, 135.01799393622008],
        [34.64016728691402, 135.0961557970448],
        [34.71309849571249, 135.3231878037398],
        [34.6693895707577, 135.44763963171033],
        [34.581749997939895, 135.47243472416568],
        [34.38272954961295, 135.30868885160834],
        [34.291920708452125, 135.08541550555094],
        [34.180442211362234, 135.17883934496956],
        [34.09813726789355, 135.1291168422266],
        [34.02162784638911, 135.1682014188574],
        [33.918549931617335, 135.08649824026315],
        [33.88317484829598, 135.16464544365715],
        [33.77992006164045, 135.24276414560495],
        [33.73859749361549, 135.3777360003703],
        [33.64405328859762, 135.4025943771406],
        [33.55831175616389, 135.45586073520735],
        [33.46943369578949, 135.77902253212864],
        [33.5582767525146, 135.8926377153304],
        [33.69729746954589, 135.98123499973786],
        [33.9037911514755, 136.11620370776888],
        [33.96269523591499, 136.20491254852692],
        [34.07443913093523, 136.20469261257801],
        [34.203833700412034, 136.32571498583462],
        [34.29190113075559, 136.6133579725157],
        [34.30375422280487, 136.81964746191187],
        [34.403434942214375, 136.91196463166534],
        [34.50288157147948, 136.7732129275297],
        [34.62287652529635, 136.52480056300845],
        [34.762959500432586, 136.51901940449687],
        [34.89985386302074, 136.6325666674112],
        [34.99001289924577, 136.64320766340091],
        [35.045228254119365, 136.74965747472783],
        [35.09169861459024, 136.89514179991932],
        [34.998742611137374, 136.86321330919532],
        [34.92313577123606, 136.83128334560672],
        [34.83580414952813, 136.85611717091888],
        [34.754213529448606, 136.8454701667805],
        [34.69880455203746, 136.9696523699161],
        [34.77461850569648, 136.9093348667327],
        [34.88239208129558, 136.92708864528913],
        [34.85327840096218, 136.99451079787042],
        [34.77753355272627, 137.10451724251203],
        [34.82414709147825, 137.23572279859627],
        [34.78044562526502, 137.32793509461317],
        [34.67545822449519, 137.20387715684166],
        [34.58202781912095, 137.02998501021443],
        [34.62291347903023, 137.2500065090639],
        [34.68130634285217, 137.45923918788117],
        [34.67546033910999, 137.65450986660954],
        [34.666708456088074, 137.92414585930788],
        [34.60831435464557, 138.19032920284826],
        [34.74546891316609, 138.25046737488265],
        [34.83288627027699, 138.3250182480275],
        [34.89982616508085, 138.3141388396342],
        [34.984192137799184, 138.4775996277113],
        [35.09034191051808, 138.55735207938523],
        [35.1368131563426, 138.72061196776474],
        [35.040983848125975, 138.8944431118441],
        [35.00611470652906, 138.78444037077577],
        [34.8839918901039, 138.77012801589365],
        [34.71203674632763, 138.75963302021432],
        [34.63319863942445, 138.81290913577203],
        [34.66833405224662, 138.96178457007977],
        [34.802511838874665, 139.0715060782561],
        [34.93343954201198, 139.1283339127728],
        [35.026430430510274, 139.0928550541422],
        [35.13954307316395, 139.1032036909197],
        [35.2382190010335, 139.14973171569085],
        [35.3107489017776, 139.330979834721],
        [35.307854057108855, 139.53680355894915],
        [35.16294656580989, 139.62916999891667],
        [35.21804778204576, 139.71431812053282],
        [35.26732492372834, 139.7498244921161],
        [35.3368164675981, 139.65393691558904],
        [35.42650319151056, 139.64327455265249],
        [35.527683042481456, 139.77110981361415],
        [35.62004430356177, 139.77109268980956],
        [35.65754386768262, 139.88466703021845],
        [35.68347843872725, 139.97691016303764],
        [35.59408066851188, 140.11179364297743],
        [35.49298500494558, 140.0513746753728],
        [35.368660878787594, 139.91301740817102],
        [35.30207025458557, 139.82427720286375],
        [35.220892438270525, 139.8701623988622],
        [35.13971362283371, 139.82775914400804],
        [34.99449446171401, 139.85268759960047],
        [34.91596053553293, 139.82791087100912],
        [34.91887197051581, 139.94143261788042],
        [35.02937103120307, 139.98748764630005],
        [35.11356866574553, 140.09362996020985],
        [35.157117067992374, 140.3493400215317],
        [35.313661959715034, 140.4063127325639],
        [35.52479861466975, 140.44896283770595],
        [35.67773918057361, 140.62642330352224],
        [35.70895591812281, 140.88154754179504],
        [35.864401638931554, 140.73606127086583],
        [36.15622310594877, 140.56570592179946],
        [36.34223327944511, 140.58700041751075],
        [36.53619606936728, 140.63298214505983],
        [36.78277382539747, 140.74401922289934],
        [36.91069317354034, 140.8080445101819],
        [36.96461641832116, 140.95358189107168],
        [37.13170967674682, 140.98904323250798],
        [37.401897671914625, 141.0390424556561],
        [37.6553328033235, 141.01422673578276],
        [37.820979457624276, 140.95384316192525],
        [38.00242716153633, 140.9050091779801],
        [38.18691988183829, 140.95828876722805],
        [38.295725392523565, 141.06485811909107],
        [38.38207273433787, 141.07903673741072],
        [38.37929434219217, 141.15718719011358],
        [38.4266103172678, 141.31701627581646],
        [38.443285172151974, 141.47325913359117],
        [38.50207540953036, 141.48202238092722],
        [38.55142664319325, 141.42419528054398],
        [38.6254181166405, 141.5371097806937],
        [38.64803302583309, 141.44244005988145],
        [38.68696585173072, 141.44516750933929],
        [38.723873233127165, 141.54503228730482],
        [38.78126776682052, 141.49497067739838],
        [38.82630433078174, 141.5792112221526],
        [38.90609374783227, 141.61594792763577],
        [38.88360945071929, 141.65810913780302],
        [39.00415683274849, 141.60801614838522],
        [39.032688343611454, 141.7024564116868],
        [39.08164429127007, 141.71297034663053],
        [39.120184599579716, 141.79392785129698],
        [39.23221310031329, 141.87551503908946],
        [39.35032427929184, 141.88640269441316],
        [39.40528207240948, 141.9601613766913],
        [39.47416313513162, 141.94424116088703],
        [39.60725123696864, 141.96756005371918],
        [39.72646504607721, 141.95931652738784],
        [39.86991689557817, 141.93588077106114],
        [39.92002407479637, 141.88030663222366],
        [39.93991820590827, 141.83274151434347],
        [40.006794744505385, 141.8884195325731],
        [40.145485248452786, 141.7708247306399],
        [40.229890948140905, 141.78926224697068],
        [40.3262296936756, 141.76560682911338],
        [40.4104283458839, 141.7024981288199],
        [40.47445921053526, 141.56304891644862],
        [40.600542721635634, 141.46313944041964],
        [40.79406531496477, 141.39999872211922],
        [40.98019775250742, 141.3792797168374],
        [41.186144971260745, 141.4002951574352],
        [41.32044918644601, 141.43707500649816],
        [41.42109038546991, 141.46074143335528],
        [41.35204837198222, 141.32673163164412],
        [41.36979548610489, 141.2006156853589],
        [41.469897890385454, 141.08512308714506],
        [41.48564320399447, 141.00633995784875],
        [41.529369585675994, 140.9141992718799],
        [41.4543701872031, 140.88804469679258],
        [41.408997798726915, 140.83556083831974],
        [41.33806675683499, 140.81715479512116],
        [41.270788379563356, 140.82258234485545],
        [41.19786944268117, 140.7961685570064],
        [41.136697391304466, 140.81702995329073],
        [41.19604410861402, 140.97991603848618],
        [41.19602266049037, 141.06137650833517],
        [41.23947470614377, 141.1218128406913],
        [41.28875187224736, 141.17437671982623],
        [41.19792939634581, 141.26893518562196],
        [41.085260425282996, 141.23739596725284],
        [40.926614599646456, 141.19799197848636],
        [40.86104075061169, 141.11922603737833],
        [40.91271402414745, 141.03510395327874],
        [40.92857550258799, 140.97733986213876],
        [40.96432684436519, 140.9746598384446],
        [41.01192334208173, 140.88795912592008],
        [40.97223759667392, 140.87223812416914],
        [40.91466340158584, 140.88543865000804],
        [40.819322648683, 140.81447793744383],
        [40.85511864634549, 140.69614537512695],
        [41.00598080853308, 140.6435993885678],
        [41.15643799519526, 140.64106998131007],
        [41.22364694855244, 140.60692793218425],
        [41.22166434661591, 140.52287471678642],
        [41.18204119705183, 140.49415029406384],
        [41.20784334811515, 140.43090838956982],
        [41.257171878372475, 140.3443333419005],
        [41.17602695643566, 140.3262507807575],
        [41.120848070014375, 140.2942395027684],
        [41.075347978206196, 140.32565986153514],
        [40.80143975163793, 140.24947641493452],
        [40.77325270049863, 140.19982267289834],
        [40.739323448265594, 140.12620538000016],
        [40.76727787784964, 140.05531930157827],
        [40.679537486413096, 139.95807387805866],
        [40.613927085645855, 139.88455309516615],
        [40.58200552471246, 139.88455299239996],
        [40.58394184894772, 139.93183170138485],
        [40.468027912335344, 139.95017931110021],
        [40.427934768781995, 139.940235414009],
        [40.35977419783228, 140.0322093893265],
        [40.22562886435625, 140.00854879267308],
        [40.01320511781498, 139.91338384592984],
        [39.954784961583414, 139.81351845992805],
        [39.97492952559429, 139.7557197232386],
        [40.00313704061591, 139.6979246140441],
        [39.9607025512251, 139.71100825132044],
        [39.90804890799566, 139.72664906854567],
        [39.86196391867347, 139.75565631730785],
        [39.86408064189132, 139.82927287463633],
        [39.904432099967465, 139.9107434391653],
        [39.85606061828537, 140.0106076060967],
        [39.72014853681412, 140.06120247749067],
        [39.50494759767221, 140.04802145738256],
        [39.294703168565746, 139.97657370951146],
        [39.2805948679422, 139.91880095476068],
        [39.199120549533234, 139.90040109485557],
        [38.963677526216685, 139.84094820843524],
        [38.77314145732177, 139.75148548126612],
        [38.67754832437754, 139.6208354700002],
        [38.51542177329071, 139.5368259472222],
        [38.3733382294711, 139.45000156563844],
        [38.17824260032333, 139.43332281619314],
        [38.03554232252267, 139.30708098475912],
        [37.95264157931217, 139.11246876812538],
        [37.86134132785473, 138.9152271129733],
        [37.7916043276017, 138.82188469985437],
        [37.66049867324847, 138.77192989459382],
        [37.55636187121921, 138.71406842893748],
        [37.48751260889691, 138.62730476245952],
        [37.378051818654384, 138.55681416009344],
        [37.34215844803482, 138.48565861241093],
        [37.243635148754976, 138.34824075380368],
        [37.16794301494595, 138.23468880921763],
        [37.170033687680686, 138.09477615543238],
        [37.105140682477824, 138.00289500157726],
        [37.03799069665746, 137.81841054416594],
        [36.97438543213953, 137.63454032112952],
        [36.93911586424096, 137.45471098604003],
        [36.81262205929819, 137.38359523326244],
        [36.75565100928952, 137.29659556168428],
        [36.75987668199613, 137.16745627205836],
        [36.789378263173106, 137.0464432765968],
        [36.865303532706804, 136.96476017100588],
        [36.91588848753824, 137.0226943073871],
        [37.00417530103282, 137.04410058710323],
        [37.12212865389222, 137.04627393084905],
        [37.06104272883735, 136.99121610685512],
        [37.07798816847372, 136.93823938590992],
        [37.075827126036444, 136.85932397859085],
        [37.134533228723456, 136.88331821912226],
        [37.233295391271554, 136.91471542763338],
        [37.19138423224551, 137.01210519194223],
        [37.2890516368538, 137.10176746639524],
        [37.30378223412599, 137.16998279168644],
        [37.31845911182067, 137.2593901197573],
        [37.366483131699624, 137.2332578118938],
        [37.445986588813156, 137.25942374704863],
        [37.45024307439702, 137.35403393659158],
        [37.51915753567823, 137.3487686674763],
        [37.53574228259586, 137.2699805739967],
        [37.49820297117117, 137.1646831562647],
        [37.44809440455958, 137.04624685306925],
        [37.40404526057489, 136.9387425493486],
        [37.39560508835119, 136.8337196686416],
        [37.33269869164734, 136.7228688603886],
        [37.21567660471945, 136.69652626141146],
        [37.14448874658396, 136.68337407009642],
        [37.152843131172915, 136.72807649361266],
        [37.06480585046664, 136.72807927213216],
        [36.98928469256772, 136.77274382405858],
        [36.92209680297216, 136.7595746770719],
        [36.87372610701347, 136.77274674392413],
        [36.60201282374415, 136.5916550966022],
        [36.45821791786278, 136.45219193475725],
        [36.307778261515324, 136.25749970653402],
        [36.19534524515274, 136.11536362079516],
        [36.10828470156116, 136.04454180012533],
        [35.983127969340956, 135.96554715685102],
        [35.9319483619508, 136.00241471240508],
        [35.891517250662204, 135.9971359825783],
        [35.77198953887432, 136.10764700258397],
        [35.66106955076134, 136.08657889694388],
        [35.66753256673239, 136.04181567368602],
        [35.75510131454149, 136.01550311988694],
        [35.725206418179596, 135.9602788578436],
        [35.63972083046123, 135.9787590390132],
        [35.61834458963742, 135.92355816697363],
        [35.55847161861759, 135.89205976157768],
        [35.571313920618266, 135.8446497928171],
        [35.534946466737765, 135.82888445919673],
        [35.532809056889064, 135.75779201509886],
        [35.487860684615995, 135.72897866163805],
        [35.479292690616774, 135.6395273273535],
        [35.492146580608264, 135.5108480557392],
        [35.5713242705277, 135.4684823635485],
        [35.547779114087035, 135.36097916832136],
        [35.517863157657914, 135.3031517197512],
        [35.552044845916555, 135.14540445219438],
        [35.60338992634351, 135.23215921406307],
        [35.686800305876574, 135.28738326226085],
        [35.782940323265436, 135.22690457918583],
        [35.723193266104076, 135.05595092985192],
        [35.64407643043826, 134.94027670258208],
        [35.65695823102618, 134.81137009487952],
        [35.65049490319902, 134.6247140306715],
        [35.66334004196442, 134.5405270926786],
        [35.62486687706797, 134.43271541685687],
        [35.58850447422199, 134.34070901217228],
        [35.51990680431494, 134.1198314217694],
        [35.51342001679464, 133.93836992488406],
        [35.50709244931315, 133.74113467210844],
        [35.524201953053534, 133.61753776605656],
        [35.4728036013107, 133.433466390187],
        [35.48144834036877, 133.28920549644067],
        [35.55543634053133, 133.28920549644067],
        [35.58077709138213, 133.12039903483702],
        [35.51947112649951, 132.96981167867506],
        [35.44970707708789, 132.74122666571066],
        [35.42432466968842, 132.63730809871163],
        [35.3205386111257, 132.66076636768446],
        [35.209912451949876, 132.45137318877835],
        [35.05930827697367, 132.287965861945],
        [34.94245523589716, 132.1323512101851],
        [34.776488834373154, 131.9223112521691],
        [34.66779263138778, 131.7537473396746],
        [34.64859398898474, 131.6033384442477],
        [34.5312149203838, 131.50231015559865],
        [34.40080460088764, 131.36744871790864],
        [34.36395011817248, 131.2494306851704]
]
    
]